import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/buttons.css';


export function LevelWall({ nextLevelFn, wallOpen, wallToggle, wallMessage, declineLink }) {

  const levelUp = () => {
    wallToggle();
  }

  if (nextLevelFn == null) nextLevelFn = levelUp;

  return (
    <div>
      <Modal isOpen={wallOpen} toggle={wallToggle}>
        <ModalHeader toggle={wallToggle}>{wallMessage}</ModalHeader>
        <ModalBody>
           <div className="bold">Ready to upgrade your account?</div>
        </ModalBody>
           <ModalFooter>
                  <a href='https://edglab.notion.site/Pricing-a25231652fb94b39afe57c40f4eaba41' target="_blank" rel="noreferrer">
                      <Button className="button-list btn downloadButton" onClick={nextLevelFn}>Upgrade</Button></a>{' '}
                  <a href={declineLink}>
                  <Button className="button-list" color="secondary" onClick={wallToggle}>Later</Button></a>
           </ModalFooter>
      </Modal>
    </div>
  );
}
