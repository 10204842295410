import { useState } from 'react';

export function useAllCompanies() {
    const [allCompanies, setAllCompanies] = useState([]);
    const [allCompaniesUrl, setAllCompaniesUrl] = useState([]);
    const [pagedCompanies, setPagedCompanies] = useState([]);
    const [companyNames, setCompanyNames] = useState([]);
    const token = localStorage.getItem("token");

    const getAllCompanies = (url) => {
        if(url == allCompaniesUrl) return;
        setAllCompaniesUrl(url);        
        fetch(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => {
            if(resp.ok) {
                return resp.json()
            } else {
                throw new Error("No companies returned")
            }
        })
        .then(data => setAllCompanies(data))
        .catch(e => console.log(e))
    }

    const getPagedCompanies = (url) => {
        fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => {
            if(resp.ok) {
                return resp.json()
            } else {
                throw new Error("No companies returned")
            }
        })
        .then(data => setPagedCompanies(data))
        .catch(e => console.log(e))
    }

    const removeDuplicateValues = (arr) => {
        let removed = Array.from(new Set(arr));
        return removed;
    }

    const getCompanyNames = (data) => {
        var companies = data.map(b => b.companyName);
        var removedDuplicates = removeDuplicateValues(companies)
        setCompanyNames(removedDuplicates);
    }

    return {  allCompanies, pagedCompanies, getAllCompanies, getPagedCompanies, getCompanyNames, companyNames };
}
