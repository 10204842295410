import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Col, FormGroup, Label, Alert } from 'reactstrap';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';
import '../stylesheets/NavMenu.css';

export function PasswordResetPage() {
    const [accountUpdated, setAccountUpdated] = useState(null);
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);
    const [alertString, setAlertString] = useState("Something went wrong.");
    const [linkExpired, setLinkExpired] = useState(false);
    const [paramsMissing, setParamsMissing] = useState(null);
    const [paramsInvalid, setParamsInvalid] = useState(null);

    const location = useLocation();
    const paramUsername = new URLSearchParams(location.search).get('username');
    const paramEmailToken = new URLSearchParams(location.search).get('emt');

    const url = new URL(window.location.href);

    // Check to see if an existing user is logged in
    const token = localStorage.getItem("token");

    useEffect(() => {
        // If an exsting user is logged in, log them out.
        if (token != null) {
            localStorage.removeItem("appToken");
            localStorage.removeItem("token");
            setLoggedOut(true);
        }

        // If this if/else is placed outside of the useEffect(), we get recurring page renders which prevents the page from working.
        if (url.searchParams.has('username') && url.searchParams.has('emt') && paramUsername != "" && paramEmailToken != "") {
            setParamsMissing(false);

            // If we call userPrecheck() without the useEffect(), the API function is called multiple times.
            // This can result in the server sending duplicate emails to the user.
            userPrecheck(paramUsername, paramEmailToken);
        } else {
            setParamsMissing(true);
            setAlertString("You are not permitted to access this page.");
        }
    }, []);

    const userPrecheck = async (paramUsername, paramEmailToken) => {
        setLoading(true);
        const response = await fetch(`app/user/invite/${paramUsername}/${paramEmailToken}`, {
            method: 'GET',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(resp => {
            if (resp.status == 401) {
                setParamsInvalid(true);
                setAlertString("You are not permitted to access this page.");
            } else if (resp.status == 400) {
                setParamsInvalid(true);
            }
            setLoading(false);
        })
        .catch(e => console.log(e));
    }

    const updatePasswordSignup = (e) => {
        setPassword(e.target.value);
        checkPassword(e);
    }

    // Account creation setters
    const checkPassword = (e) => {
        if (document.getElementById('password').value == document.getElementById('passwordcheck').value) {
            document.getElementById('passwordMatchMessage').style.color = 'green';
            document.getElementById('passwordMatchMessage').innerHTML = 'Passwords match';
            return true;
        }
        else {
            document.getElementById('passwordMatchMessage').style.color = 'red';
            document.getElementById('passwordMatchMessage').innerHTML = 'Passwords do not match';
            return false;
        }
    }

    const updateUser = async (e, paramEmailToken) => {
        setLoading(true);
        setAccountUpdated(false);
        const response = await fetch(`app/user/setpassword/${paramEmailToken}`, {
            method: 'PUT',
            body: JSON.stringify({
                Username: paramUsername,
                Password: password,
                client_id: "",
                client_url: window.location.origin + "/",
                PasswordResetToken: paramEmailToken
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(resp => {
        if (resp.status == 400) {
            setAccountUpdated(false);
            resp.text().then((text) => {
                setAlertString(text);
                if (text == "User not found.") {
                    setParamsInvalid(true);
                }
            });
        } else if (resp.status == 428) {
            setLinkExpired(true);
            resp.text().then((text) => {
                setAlertString(text);
            });
        } else {
            if (resp.ok) {
                setAccountUpdated(true);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            } else {
                setAccountUpdated(false);
            }
        }
        setLoading(false);
        })
        .catch(e => console.log(e));
    }

    const updateAccount = async (e) => {
        if (checkPassword(e)) {
            await updateUser(e, paramEmailToken);
        }
        else {
            setAccountUpdated(false);
            setAlertString("Password and Confirm Password fields do not match.")
        }
    }

    // Instead of the button reloading the page with the Button's href attribute, we call this function.
    // This preserves state (versus reloading) and allows us to prepopulate the form fields.
    const reloadPage = () => {
        setAccountUpdated(null);
        setLinkExpired(false);
    }

    if (loading === true) {
        return (
            <span>Loading...</span>
        )
    }

    if (paramsMissing === true || paramsInvalid === true) {
        return (
            <Alert className='alert-alignment' color='danger'>{`${alertString}`}
                <Button className='alert-link' color='link' href="/" >Go to login page?</Button>
            </Alert>
        )
    }

    if (linkExpired == true) {
        return (
            <Alert className='alert-alignment' color='primary'>{`${alertString}`}
                <Button className='alert-link' color='link' href="/passwordRequest" >Request password reset?</Button>
            </Alert>
        )
    }

    if (accountUpdated === true) {
        return (
            <Alert className='alert-alignment' color='primary'>{`${alertString}`}
                <Button className='alert-link' color='link' href="/" >Proceed to the login page?</Button>
            </Alert>
        )
    } else if (accountUpdated === false) {
        return (
            <Alert className='alert-alignment' color='danger'>{`${alertString}`}
                <Button color='link' className='alert-link' onClick={() => reloadPage()}>Try again?</Button>
            </Alert>
        )
    }

    return (
        <Col sm="12" md={{ size: 6, offset: 3 }}>
            <div className='modal-head modal-header'>
                <h4 className='logo-text'>Set your new password to continue.</h4>
            </div>
            <Form onSubmit={(e) => updateAccount(e)}>
                <FormGroup className='form-space' row>
                    <Label for="username" sm={3}>Email</Label>
                    <Col className="presetData" sm={9}>
                        {paramUsername}
                    </Col>
                </FormGroup>
                <FormGroup className='form-space' row>
                    <Label for="password" sm={3}>Password</Label>
                    <Col sm={9}>
                        <Input onChange={e => updatePasswordSignup(e)} type="password" name="password" id="password" minLength="7" required />
                    </Col>
                </FormGroup>
                <FormGroup className='form-space' row>
                    <Label for="passwordcheck" sm={3}>Confirm Password</Label>
                    <Col sm={9}>
                        <Input onChange={e => checkPassword(e)} type="password" name="passwordcheck" id="passwordcheck" required />
                        <span id='passwordMatchMessage'></span>
                    </Col>
                </FormGroup>
                <div className='btn-toolbar justify-content-center'>
                    <input value="Set New Password" type="submit" className="form-space btn downloadButton" />
                </div>
            </Form>
            <hr className='link-footer-hr' />
            <span className='link-footer-content'>
                <Link to='/login'>Return to login page</Link>
            </span>
        </Col>
    )
}