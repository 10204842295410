import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { DashboardPage } from './components/DashboardPage';
import { DataRangeSelectPage } from './components/DataRangeSelectPage';
import { PasswordRequestPage } from './components/PasswordRequestPage';
import { BoardDataPage } from './components/BoardDataPage';
import { RegisterBoardPage } from './components/RegisterBoardPage';
import { LoginPage } from './components/LoginPage';
import { SignupPage } from './components/SignupPage';
import { SignupFinishPage } from './components/SignupFinishPage';
import PrivateRoute from './components/PrivateRoute';
import { LogoutPage } from './components/LogoutPage';
import { InviteUserPage } from './components/InviteUserPage';
import { ManageUsersPage } from './components/ManageUsersPage';
import { RegisterUserPage } from './components/RegisterUserPage';
import { RegisterCompanyPage } from './components/RegisterCompanyPage';
import { ManageBoardsPage } from './components/ManageBoardsPage';
import { ManageCompaniesPage } from './components/ManageCompaniesPage';
import { EditUserPage } from './components/EditUserPage';
import { EmailVerifyPage } from './components/EmailVerifyPage';
import { PasswordResetPage } from './components/PasswordResetPage';

export default function App() {
  return (
      <Layout>
        <Route path='/passwordRequest' component={PasswordRequestPage} />
        <Route path='/login' component={LoginPage} />
        <Route path='/passwordReset' component={PasswordResetPage} />
        <Route path='/signup' component={SignupPage} />
        <Route path='/signupFinish' component={SignupFinishPage} />
        <PrivateRoute exact path='/' component={DashboardPage} />
        <PrivateRoute path='/dataRangeSelect' component={DataRangeSelectPage} />
        <PrivateRoute path='/registerBoard' component={RegisterBoardPage} />
        <PrivateRoute exact path='/boarddata' component={BoardDataPage} />
        <PrivateRoute exact path='/boarddata/:companyId' component={BoardDataPage} />
        <PrivateRoute exact path='/boarddata/pcbSn/:pcbSN' component={BoardDataPage} />
        <PrivateRoute path='/logout' component={LogoutPage} />
        <PrivateRoute path='/viewUsers' component={ManageUsersPage} />
        <PrivateRoute path='/editUser/:id' component={EditUserPage} />
        <PrivateRoute path='/inviteUser' component={InviteUserPage} />
        <PrivateRoute exact path='/manageBoards' component={ManageBoardsPage} />
        <PrivateRoute exact path='/manageBoards/:companyId' component={ManageBoardsPage} />
        <PrivateRoute path='/manageCompanies' component={ManageCompaniesPage} />
        <PrivateRoute path='/registerUser' component={RegisterUserPage} />
        <PrivateRoute path='/registerCompany' component={RegisterCompanyPage} />
        <Route exact path='/emailVerify' component={EmailVerifyPage} />
      </Layout>
  );
}