import React, { useState, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useRole } from './hooks/useRole';
import logo from '../imgs/edg_logo_no-border.png';
import '../stylesheets/logo.css';
import '../stylesheets/NavMenu.css';

export function NavMenu() {
  const [collapsed, setCollapsed] = useState(false);
  const [navHeader, setNavHeader] = useState("Engineering Design Group");
  const { userRole } = useRole();

  useEffect(() => {
    switch(userRole) {
      case "Admin": {
        setNavHeader("Admin Portal");
        break;
      }
      case "Client": {
        setNavHeader("Client Portal");
        break;
      }
      case "ReadOnly": {
        setNavHeader("Client Portal (limited access)");
        break;
      }
      case "Tech": {
        setNavHeader("Tech Portal");
        break;
      }
      case "EndClient": {
        setNavHeader("Client Portal");
        break;
      }
      case "GuestClient": {
        setNavHeader("Client Portal");
        break;
      }
      default: {
        setNavHeader("Portal");
        break;
      }
    }
  }, [userRole])

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }
  if(userRole === "Admin") {
    return (
      <header>
        <Navbar id="navbar" className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container fluid={true}>
            <NavbarBrand tag={Link} to={localStorage.getItem("token") ? "/" : "/login"}>
              <img className='nav-logo' src={logo} alt="#" />
              <p className='navbar-brand nav-space'>{navHeader}</p>
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="nav-layout d-sm-inline-flex flex-sm-row-reverse" isOpen={collapsed} navbar>
              <ul className={localStorage.getItem("token") ? "navbar-nav flex-grow" : "hidden"}>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/">Dashboard</a>
                </NavItem>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/boarddata">Board Data</a>
                </NavItem>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/manageBoards">Manage Boards</a>
                </NavItem>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/manageCompanies">Manage Companies</a>
                </NavItem>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/viewUsers">Manage Users</a>
                </NavItem>
                <NavItem className={localStorage.getItem("token") ? "nav-space" : "hidden"}>
                  <a className="text-dark" href="/logout">{localStorage.getItem("token") ? "Logout" : "Login"}</a>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    )
  } else if (userRole === "Client" || userRole === "Tech") {
      return (
        <header>
          <Navbar id="navbar" className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
            <Container fluid={true}>
              <NavbarBrand tag={Link} to={localStorage.getItem("token") ? "/" : "/login"}>
                <img className='nav-logo' src={logo} alt="#" />
                <p className='navbar-brand nav-space'>{navHeader}</p>
              </NavbarBrand>
              <NavbarToggler onClick={toggleNavbar} className="mr-2" />
              <Collapse className="nav-layout d-sm-inline-flex flex-sm-row-reverse" isOpen={collapsed} navbar>
                <ul className={localStorage.getItem("token") ? "navbar-nav flex-grow" : "hidden"}>
                  <NavItem className="nav-space">
                    <a className="text-dark" href="/">Dashboard</a>
                  </NavItem>
                  <NavItem className="nav-space">
                    <a className="text-dark" href="/boarddata">Board Data</a>
                  </NavItem>
                  <NavItem className="nav-space">
                    <a className="text-dark" href="/manageBoards">Manage Boards</a>
                  </NavItem>
                  <NavItem className="nav-space">
                    <a className="text-dark" href="/viewUsers">Manage Users</a>
                  </NavItem>
                  <NavItem className={localStorage.getItem("token") ? "nav-space" : "hidden"}>
                    <a className="text-dark" href="/logout">{localStorage.getItem("token") ? "Logout" : "Login"}</a>
                  </NavItem>
                </ul>
              </Collapse>
            </Container>
          </Navbar>
        </header>
      )
  } else if (userRole === "EndClient" || userRole == "GuestClient") {
    return (
      <header>
        <Navbar id="navbar" className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container fluid={true}>
            <NavbarBrand tag={Link} to={localStorage.getItem("token") ? "/" : "/login"}>
              <img className='nav-logo' src={logo} alt="#" />
              <p className='navbar-brand nav-space'>{navHeader}</p>
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="nav-layout d-sm-inline-flex flex-sm-row-reverse" isOpen={collapsed} navbar>
              <ul className={localStorage.getItem("token") ? "navbar-nav flex-grow" : "hidden"}>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/">Dashboard</a>
                </NavItem>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/boarddata">Board Data</a>
                </NavItem>
                <NavItem className={localStorage.getItem("token") ? "nav-space" : "hidden"}>
                  <a className="text-dark" href="/logout">{localStorage.getItem("token") ? "Logout" : "Login"}</a>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    )
  } else if (userRole === "ReadOnly") {
    return (
      <header>
        <Navbar id="navbar" className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container fluid={true}>
            <NavbarBrand tag={Link} to={localStorage.getItem("token") ? "/" : "/login"}>
              <img className='nav-logo' src={logo} alt="#" />
              <p className='navbar-brand nav-space'>{navHeader}</p>
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="nav-layout d-sm-inline-flex flex-sm-row-reverse" isOpen={collapsed} navbar>
              <ul className={localStorage.getItem("token") ? "navbar-nav flex-grow" : "hidden"}>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/">Dashboard</a>
                </NavItem>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/boarddata">Board Data</a>
                </NavItem>
                <NavItem className="nav-space">
                  <a className="text-dark" href="/manageBoards">View Boards</a>
                </NavItem>
                <NavItem className={localStorage.getItem("token") ? "nav-space" : "hidden"}>
                  <a className="text-dark" href="/logout">{localStorage.getItem("token") ? "Logout" : "Login"}</a>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    )
    } else {
        return (
            <header>
                <Navbar id="navbar" className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container fluid={true}>
                        <NavbarBrand tag={Link} to={localStorage.getItem("token") ? "/" : "/login"}>
                            <img className='nav-logo' src={logo} alt="#" />
                            <p className='navbar-brand nav-space'>{navHeader}</p>
                        </NavbarBrand>
                        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                        <Collapse className="nav-layout d-sm-inline-flex flex-sm-row-reverse" isOpen={collapsed} navbar>
                          <ul className={localStorage.getItem("token") ? "navbar-nav flex-grow" : "hidden"}>
                            <NavItem className={localStorage.getItem("token") ? "nav-space" : "hidden"}>
                              <a className="text-dark" href="/logout">{localStorage.getItem("token") ? "Logout" : "Login"}</a>
                            </NavItem>
                          </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        )
    }
}
