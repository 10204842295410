import React, { useState, useEffect } from 'react'; 
import { Form, Input, Button, Col, FormGroup, Label, Alert } from 'reactstrap';
import { ConfirmDelete } from './ConfirmDelete';
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/forms.css';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';
import { useParams } from 'react-router-dom';
import { userRoles, useRole } from './hooks/useRole';
import { UserRoleComponent } from './UserRoleComponent';

export function EditUserPage() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [user, setUser] = useState({});
    const [userReady, setUserReady] = useState(false);
    const [role, setRole] = useState(user.userRole);
    const [firstName, setFirstname] = useState(user.firstName);
    const [lastName, setLastname] = useState(user.lastName);
    const [phone, setPhone] = useState(user.phone);
    const [showAlert, setShowAlert] = useState(false);
    const [deleted, setDeleted] = useState(null);
    const token = localStorage.getItem("token");
    const { id } = useParams();
    const { userRole, username } = useRole();

    useEffect(() => {
        if (userReady === false) {
            fetch(`api/users/${id}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(resp => resp.json())
            .then(data => {
                setUser(data);
                setUserReady(true);
            })
            .catch(e => console.log(e));
        }
        
        if (userReady === true) {
            // Now that the page has rendered, update the checkbox so the setting is visible
            document.getElementById(user.userRole).checked = true;
        }
    }, [id, token, userReady]);

    const [deleteModal, setDeleteModal] = useState(false);

    const deleteToggle = () => {
      setDeleteModal(!deleteModal);
    }

    const updateRole = (e) => {
        setRole(e.target.value);
        window.newRole = e.target.value;
    }

    const updateFirstName = (e) => {
        setFirstname(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    }

    const updateLastName = (e) => {
        setLastname(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    }

    const updatePhone = (e) => {
        var onlyNumbers = e.target.value.replace(/\D/g, "");
        setPhone('+1' + onlyNumbers);
      
    }

    const handleDelete = () => {
        setLoading(true);
        fetch(`api/users/${id}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => {
            resp.ok ? setSuccess(true) : setSuccess(false);
            setLoading(false);
        })
        .catch(e => console.log(e));
    }

    const editUser = (e) => {
        e.preventDefault();

        var answer = true;

        if (username == user.username) {
            answer = window.confirm("Are you sure you want to edit your own account?\nChanging your own user role can lead to potential issues");
        }
        if (answer) {
            setLoading(true);
            fetch(`api/users/${user.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    id: user.id,
                    username: user.username,
                    firstName: firstName === undefined ? user.firstName : firstName,
                    lastName: lastName === undefined ? user.lastName : lastName,
                    phone: phone === undefined ? user.phone : phone,
                    userRole: role === undefined ? user.userRole : Number(role),
                    companyId: user.companyId,
                    companyName: user.companyName,
                    AddedBy: user.AddedBy,
                    PasswordResetToken: user.PasswordResetToken,
                    LastVerified: user.lastVerified
                }),
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })
            .then(resp => {
                resp.ok ? setSuccess(true) : setSuccess(false);
                setLoading(false);
            })
            .catch(e => console.log(e));
            setShowAlert(true);
        }
    }

    if(success === true) {
        return (
            <Alert className='alert-alignment' color="success">{`Successfully updated ${user.username}.`}
                <Button color='link' className='alert-link' href='/viewUsers'>View all users</Button>
            </Alert>
        )
    } else if(success === false) {
        return (
            <Alert className='alert-alignment' color="danger">Something went wrong. 
                <Button color='link' className='alert-link' href={`/editUser/${id}`}>Back</Button>
            </Alert>
        )
    }

    if(deleted === false) {
        return (
            <Alert className='alert-alignment' color='danger'>Something went wrong when deleting this user.
                <Button color='link' href='/viewUsers' className='alert-link'>Back</Button>
            </Alert>
        )
    }

    if(deleted === true) {
        return (
            <Alert className='alert-alignment' color='success'>{`Successfully deleted ${user.username}.`}
                <Button color='link' href='/viewUsers' className='alert-link'>Back</Button>
            </Alert>
        )
    }

    if (loading === true) {
        return (
            <span>Loading...</span>
        )
    }

    if (user.userRole !== undefined) {
        document.forms["updateUserForm"].radio1[user.userRole - userRoles[userRole]].checked = true;
        if (window.newRole !== undefined) {
            document.getElementById(window.newRole).checked = true;
        }
    }

    return (
        <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Form onSubmit={(e) => editUser(e)} className={showAlert ? 'hidden' : ''} id="updateUserForm">
                <h1>{`Edit user: ${user.username} `}</h1>
                <FormGroup row>
                    <Label for="firstname" sm={2}>First Name</Label>
                    <Col sm={10}>
                        <Input onChange={e => updateFirstName(e)} type="text" name="firstname" id="firstname" placeholder={user.firstName}/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="lastname" sm={2}>Last Name</Label>
                    <Col sm={10}>
                        <Input onChange={e => updateLastName(e)} type="text" name="lastname" id="lastname" placeholder={user.lastName} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="phone" sm={2}>Phone Number</Label>
                    <Col sm={10}>
                        <Input onChange={e => updatePhone(e)} type="tel" name="phone" id="phone"  placeholder={user.phone} minLength="10"/>
                    </Col>
                </FormGroup>
                <h5 className='form-header'>Change User Role:</h5>
                <Col>

                    <UserRoleComponent userRole={userRole} setDefault={role == 4 ? true : false} onChange={e => updateRole(e)} />
                    
                    <div className='btn-toolbar justify-content-center'>
                        <input value="Submit" type="submit" className="button-list btn downloadButton" />

                    <ConfirmDelete
                      deleteModal={deleteModal}
                      deleteToggle={deleteToggle}
                      deleteSelected={handleDelete}
                      buttonLabel="Delete User"
                      deleteButtonClass='button-list'
                      deleteMessage={`${user.username} from the database.`}
                    />

                        <Button className='button-list' color="secondary" href='/viewUsers'>Cancel</Button>
                    </div>
                </Col>
            </Form>
        </Col>
    )
}
