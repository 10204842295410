import React, { useState } from 'react';
import { Table, FormGroup } from 'reactstrap';
import { usePageNumber } from './hooks/usePageNumber';
import { UserRow } from './UserRow';
import '../stylesheets/custom.css';

export function UsersTable({ allUsers, setCompany, resetPages, title, showError, delUser, reinviteUser, loading }) {
    const { pageNumber } = usePageNumber();
    const [pagedUsers, setPagedUsers] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);
    const token = localStorage.getItem("token");

    const handleFilterByCompany = (u) => {
        fetch(`api/users/${u.companyId}/${pageNumber}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => setPagedUsers(data))
        .catch(e => console.log(e));
        setCompany(u.companyId);
        resetPages();
    }

    if (loading) {
        return (
            <React.Fragment>
                <h3 className='table-header'>{title}</h3>
                <span>Loading...</span>
            </React.Fragment>
        );
    } else {

        return (
            <FormGroup check>
                <h3 className='table-header'>{title}</h3>
                <Table striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Email</th>
                            <th>User Role</th>
                            <th>Company</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Phone</th>
                            <th>Actions</th>
                            <th>View Boards</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allUsers.map(u => {
                                return (
                                    <UserRow
                                        key={u.id}
                                        user={u}
                                        onFilterByCompany={() => handleFilterByCompany(u)}
                                        showError={showError}
                                        delUser={delUser}
                                        reinviteUser={reinviteUser}
                                    />
                                )
                            }
                            )
                        }
                    </tbody>
                </Table>
            </FormGroup>
        );
    }
}