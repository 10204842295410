import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Col, FormGroup, Label, Alert } from 'reactstrap';
import { Link } from "react-router-dom";
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';
import '../stylesheets/NavMenu.css';

export function SignupPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);
    const [alertString, setAlertString] = useState("Something went wrong.");
    const [accountCreated, setAccountCreated] = useState(null);

    //constants used for account creation
    const [companyname, setCompanyName] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [phoneSubmit, setPhoneSubmit] = useState("");
    const [phoneDisplay, setPhoneDisplay] = useState("");

    //variables used for account creation
    var companyId = "";
    var userrole = "Client";
 
    useEffect(() => {
        localStorage.removeItem("appToken");
        localStorage.removeItem("token");
        setLoggedOut(true);
    }, [loggedOut]);

    const updateUsername = (e) => {
        setUsername(e.target.value);
    }
    const updatePasswordSignup = (e) => {
        setPassword(e.target.value);
        checkPassword(e);
    }

    //account creation setters
    const checkPassword = (e) => {
        if (document.getElementById('password').value == document.getElementById('passwordcheck').value) {
            document.getElementById('passwordMatchMessage').style.color = 'green';
            document.getElementById('passwordMatchMessage').innerHTML = 'Passwords match';
            return true;
        }
        else {
            document.getElementById('passwordMatchMessage').style.color = 'red';
            document.getElementById('passwordMatchMessage').innerHTML = 'Passwords do not match';
            return false;
        }

    }
    const updateCompanyName = (e) => {
        setCompanyName(e.target.value);
    }
    const updateFirstName = (e) => {
        setFirstName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    }
    const updateLastName = (e) => {
        setLastName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    }
    const updatePhone = (e) => {
        var onlyNumbers = e.target.value.replace(/\D/g, "");
        setPhoneSubmit('+1' + onlyNumbers);
        setPhoneDisplay(e.target.value);
    }

    const createUser = async (e) => {
        setLoading(true);
        setAccountCreated(false);
        await fetch('app/newuser', {
            method: 'POST',
            body: JSON.stringify({
                Username: username,
                Password: password,
                FirstName: firstname,
                LastName: lastname,
                UserRole: userrole,
                Phone: phoneSubmit,
                CompanyId: companyId,
                CompanyName: companyname,
                client_id: "",
                client_url: window.location.origin + "/"
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(resp => {
            if (resp.status == 403) {
                setAccountCreated(false);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            } else if (resp.status == 428) {
                setAccountCreated(false);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            } else {
                if (resp.ok) {
                    setAccountCreated(true);
                } else {
                    setAccountCreated(false);
                }
            }
            setLoading(false);
        })
        .catch(e => console.log(e));
    }

    const createAccount = async (e) => {
        if (checkPassword(e)) {
            await createUser(e);
        }
        else {
            setAccountCreated(false);
            setAlertString("Password and Confirm Password fields do not match.")
        }
    }

    // Instead of the button reloading the page with the Button's href attribute, we call this function.
    // This preserves state (versus reloading) and allows us to prepopulate the form fields.
    const reloadPage = () => {
        setAccountCreated(null);
    }

    if(loading === true) {
        return (
            <span>Loading...</span>
        )
    }

    if (accountCreated === true) {
        return (
            <Alert className='alert-alignment' color='primary'>
                Your account has been created. You have 24 hours to check your email and verify your username. Otherwise your account will be purged from the system.
                <a className='alert-link nav-space' href='/'>Once you&apos;ve verified your username, you may login to your account.</a>
            </Alert>
        )
    } else if (accountCreated === false) {
        return (
            <Alert className='alert-alignment' color='danger'>{`${alertString}`}
                <Button color='link' className='alert-link' onClick={() => reloadPage()}>Try again?</Button>
            </Alert>
        )
    }
    
    return (
        <Col sm="12" md={{ size: 6, offset: 3 }}>
            <div className='modal-head modal-header'>
                <h4 className='logo-text'>Create your free account</h4>
            </div>
            <Form onSubmit={(e) => createAccount(e)}>
                <FormGroup className='form-space' row>
                    <Label for="username" sm={3}>Email</Label>
                    <Col sm={9}>
                        <Input onChange={e => updateUsername(e)} type="username" name="username" id="username" value={username} required />
                    </Col>
                </FormGroup>
                <FormGroup className='form-space' row>
                    <Label for="password" sm={3}>Password</Label>
                    <Col sm={9}>
                        <Input onChange={e => updatePasswordSignup(e)} type="password" name="password" id="password" minLength="7" required />
                    </Col>
                </FormGroup>
                <FormGroup className='form-space' row>
                    <Label for="passwordcheck" sm={3}>Confirm Password</Label>
                    <Col sm={9}>
                        <Input onChange={e => checkPassword(e)} type="password" name="passwordcheck" id="passwordcheck" required />
                        <span id='passwordMatchMessage'></span>
                    </Col>
                </FormGroup>
                <FormGroup className='form-space' row>
                    <Label for="firstname" sm={3}>First Name</Label>
                    <Col sm={9}>
                        <Input onChange={e => updateFirstName(e)} type="firstname" name="firstname" id="firstname" value={firstname} required />
                    </Col>
                </FormGroup>
                <FormGroup className='form-space' row>
                    <Label for="lastname" sm={3}>Last Name</Label>
                    <Col sm={9}>
                        <Input onChange={e => updateLastName(e)} type="lastname" name="lastname" id="lastname" value={lastname} required />
                    </Col>
                </FormGroup>
                <FormGroup className='form-space' row>
                    <Label for="phoneDisplay" sm={3}>Phone Number</Label>
                    <Col sm={9}>
                        <Input onChange={e => updatePhone(e)} type="phoneDisplay" name="phoneDisplay" id="phoneDisplay" value={phoneDisplay} minLength="10" required />
                    </Col>
                </FormGroup>
                <FormGroup className='form-space' row>
                    <Label for="companyname" sm={3}>Company Name <em>(optional)</em></Label>
                    <Col sm={9}>
                        <Input onChange={e => updateCompanyName(e)} type="companyname" name="companyname" id="companyname" value={companyname} />
                    </Col>
                </FormGroup>
                <div className='btn-toolbar justify-content-center'>
                    <input value="Create Account" type="submit" className="form-space btn downloadButton" />
                </div>
            </Form>
            <hr className='link-footer-hr' />
            <span className='link-footer-content'>
                <Link to='/login'>Return to login page</Link>
            </span>
        </Col>
    )

}