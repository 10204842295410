import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Col, FormGroup, Label, Alert, DropdownMenu, DropdownToggle, DropdownItem, Dropdown } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/forms.css';
import '../stylesheets/buttons.css';
import '../stylesheets/alert.css';
import { userRoles, useRole } from './hooks/useRole';
import { checkPermission, UserRoleComponent } from './UserRoleComponent';
import { LevelWall } from './LevelWallComponent';

export function InviteUserPage() {
    const [allCompanies, setAllCompanies] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allUsersReady, setAllUsersReady] = useState(null);
    const [companyId, setCompanyId] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [usernameInvite, setUsernameInvite] = useState("");
    const [showAlert, setShowAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [validated, setValidated] = useState(null);
    const [role, setRole] = useState(4);
    const [alertString, setAlertString] = useState("Please fill out all required fields.");
    const token = localStorage.getItem("token");
    const { userRole, username } = useRole();
    const [levelWallUp, setLevelWallUp] = useState(false);

    useEffect(() => {
        if (allUsersReady === false || allUsersReady === null) {
            fetch(`api/companies`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(resp => resp.json())
                .then(data => setAllCompanies(data))
                .catch(e => console.log(e));

            fetch(`api/users/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(resp => resp.json())
            .then(data => {
                setAllUsers(data);
                setAllUsersReady(true);
            })
            .catch(e => console.log(e));
        }

        if (!checkPermission(0, userRole) && allUsersReady) {
            var firstCompany = allUsers[0];

            setCompanyId(firstCompany.companyId);
            setCompanyName(firstCompany.companyName);
        }

        if (document.getElementById(role) != null) {
            // On 'Try again?', we set the appropriate value only after the page has rendered.
            // This is prompted by a change in showAlert in the reloadPage() function.
            document.getElementById(role).checked = true;
        }
    }, [allUsersReady, token, showAlert]);

    const levelWallToggle = () => {
        setLevelWallUp(!levelWallUp);
    }

    const updateUsernameInvite = (e) => {
        setUsernameInvite(e.target.value);
    }

    const updateRole = (e) => {
        setRole(e.target.value);
    }

    const handleSelectedCompany = (c) => {
        setCompanyId(c.id);
        setCompanyName(c.companyName);
    }

    const inviteUser = (e) => {
        setLoading(true);
        e.preventDefault();
        if (usernameInvite === "") {
            setValidated(false);
        } else {
            fetch(`api/users/invite/${companyId}`, {
                method: 'POST',
                body: JSON.stringify({
                    username: usernameInvite,
                    userRole: Number(role),
                    companyId: companyId,
                    AddedBy: username
                }),
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })
            .then(resp => {
                if (resp.status == 403) {
                    setSuccess(null);
                    setValidated(false);
                    resp.text().then((text) => {
                        setAlertString(text);
                        if (text.search("Free") >= 0) {
                            setLevelWallUp(true);
                        }
                    });
                } else {
                    if (resp.ok) {
                        setSuccess(true);
                    } else {
                        setSuccess(false);
                    }
                }
                setLoading(false);
            })
            .catch(e => console.log(e));
            setShowAlert(true);
        }
    }

    const onToggle = () => setDropdownOpen(!dropdownOpen);

    // Instead of the button reloading the page with the Button's href attribute, we call this function.
    // This preserves state (versus reloading) and allows us to prepopulate the form fields.
    const reloadPage = () => {
        setAlertString("Please fill out all required fields.");
        setShowAlert(null);
        setSuccess(null);
        setValidated(null);
    }

    if (loading === true) {
        return (
            <span>Loading...</span>
        )
    }

    if (success === true) {
        return (
            <Alert className='alert-alignment' color="primary">{`A new user has been successfully invited to join ${companyName.indexOf("auto_") == 0 ? "your company" : companyName} on the EDG Client Portal. To complete their registration, they must check their email and verify their account within the next 24 hours.`}
                <div>
                    <Button color='link' className='alert-link' href='/inviteUser'>Invite another user</Button>
                    <Button color='link' className='alert-link' href='/viewUsers'>View all users</Button>
                </div>
            </Alert>
        )
    } else if (success === false) {
        return (
            <Alert className='alert-alignment' color='danger'>{`${alertString}`}
                <Button color='link' className='alert-link' onClick={() => reloadPage()}>Try again?</Button>
            </Alert>
        )
    }

    if(validated === false) {
        return (
          <div>
            <Alert className='alert-alignment' color="danger">{`${alertString}`}
                <Button color='link' className='alert-link' onClick={() => reloadPage()}>Try again?</Button>
            </Alert>
            <LevelWall
                wallOpen={levelWallUp}
                wallToggle={levelWallToggle}
                wallMessage={alertString}
                declineLink="/viewUsers"
            />
          </div>
        );
    }

    return (
        <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Form onSubmit={(e) => inviteUser(e)} className={showAlert ? 'hidden' : ''}>
                {checkPermission(0, userRole) ?
                    <h1 className="table-header">Invite Someone New to a Company</h1>
                    :
                    <h1 className="table-header">Invite Someone New to {`${companyName.indexOf("auto_") == 0 ? "Your Company" : companyName}`}</h1>
                }
                {checkPermission(0, userRole) ?
                    <FormGroup>
                        <Dropdown isOpen={dropdownOpen} toggle={onToggle}>
                            <DropdownToggle className='primaryColor button-list' caret>
                                Select Company
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>Companies</DropdownItem>
                                {
                                    allCompanies.map(c => {
                                        return (
                                            <div key={c.id}>
                                                <DropdownItem onClick={() => handleSelectedCompany(c)}>{c.companyName}</DropdownItem>
                                            </div>
                                    )})
                                }
                            </DropdownMenu>
                        </Dropdown>
                        <h5 className={companyName === "" ? 'hidden' : 'space'}>{`Selected company: ${companyName}`}</h5>
                    </FormGroup>
                    :
                    <FormGroup>
                        <input type="hidden" name="companyName" id="companyName" value={companyName} />
                    </FormGroup>
                }
                <FormGroup row>
                    <Label for="usernameInvite" sm={2}>Email</Label>
                    <Col sm={10}>
                        <Input onChange={e => updateUsernameInvite(e)} type="text" name="usernameInvite" id="usernameInvite" value={usernameInvite} required />
                    </Col>
                </FormGroup>
                <h5 className='form-header'>User Role:</h5>
                <Col>

                    <UserRoleComponent userRole={userRole} setDefault={role == 4 ? true : false} onChange={e => updateRole(e)} />

                    <div className='btn-toolbar justify-content-center'>
                        <input value="Invite User" type="submit" className="button-list btn downloadButton" />
                        <Button className='button-list' color="secondary" href='/viewUsers'>Cancel</Button>
                    </div>
                </Col>
            </Form>
        </Col>
    )
}