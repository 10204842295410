import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute ({ component: Component, ...rest }) {
  var isAuthenticated = false;
  const token = localStorage.getItem("token");

  if(token) {
    var base64Payload = token.split('.')[1];

    var payload = window.atob(base64Payload);

    var payloadValues = payload.valueOf();
    var parsedPayload = JSON.parse(payloadValues);
    var now = new Date();
    now = now.getTime() / 1000;

    if(parsedPayload.exp > now) {
      isAuthenticated = true;
    } 
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: {} }} />
        )
      }
    />
  )
}