import { useState } from 'react';

export function useSearchText() {
    const [searchText, setSearchText] = useState("");

    const updateSearchText = (text) => {
        setSearchText(text);
    }

    return { searchText, updateSearchText };
}