import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/forms.css';
import '../stylesheets/buttons.css';
import '../stylesheets/alert.css';
import { userRoles } from './hooks/useRole';

export const checkPermission = (permissionVal, userRole) => {
  var userVal = userRoles[userRole];
  
  if (permissionVal == 2 && userVal == 3) {
    // Special case: Grant features of ReadOnly (2) to Tech (3)
    return true;
  }
  else if (userVal <= permissionVal) {
    return true;
  }
  else return false;
}

export function UserRoleComponent(props) {

  return (
    <div>
      { checkPermission(0, props.userRole) ? < FormGroup className='form-item' check >
        <Label check>
          <Input onChange={props.onChange} type="radio" value={userRoles.Admin} name="radio1" id="0" />{' '}
                <span className='bold'>Admin</span> - A user with Administrator level privileges spanning all Companies, an employee of
                Engineering Design Group. (All others below belong to a specific Company or Team which may not have a name.)
        </Label>
        </FormGroup > : null
      }
      {
        checkPermission(1, props.userRole) ? <FormGroup className='form-item' check>
          <Label check>
            <Input onChange={props.onChange} type="radio" value={userRoles.Client} name="radio1" id="1" />{' '}
            <span className='bold'>Client</span>  - Can read data owned by the Company. Can create Boards/Devices.
                                  Can invite or create other users of all types below. A Client is an employee or owner of the Company.
                              </Label>
        </FormGroup> : null
      }
      {
        checkPermission(2, props.userRole) ? <FormGroup className='form-item' check>
          <Label check>
            <Input onChange={props.onChange} type="radio" value={userRoles.ReadOnly} name="radio1" id="2" />{' '}
            <span className='bold'>ReadOnly</span> - Can read data of the Company. Cannot register or invite users,
                                  or see any other users who have access to the Company&apos;s data.
                                  A ReadOnly user cannot register boards, rename boards, or assign boards to groups.
                              </Label>
        </FormGroup> : null
      }
      {
        checkPermission(3, props.userRole) ? <FormGroup className='form-item' check>
          <Label check>
            <Input onChange={props.onChange} type="radio" value={userRoles.Tech} name="radio1" id="3" />{' '}
            <span className='bold'>Tech</span> - Can read data and create Boards/Devices. 
                Can invite or create users of all types including Tech or below. 
                A Tech user may register boards, rename boards, and assign boards to groups.
                A Tech is an employee of the Company.
                              </Label>
        </FormGroup> : null
      }
      {
        checkPermission(4, props.userRole) ? <FormGroup className='form-item' check>
          <Label check>
            <Input onChange={props.onChange} type="radio" value={userRoles.EndClient} name="radio1" id="4" checked={props.setDefault ? true : false} />{' '}
            <span className='bold'>EndClient</span> - Can read data of their assigned Company.
                              </Label>
        </FormGroup> : null
      }
    </div>
  );
}
