import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { useRole } from './hooks/useRole';
import '../stylesheets/custom.css';

export function DashboardPage() {
  const { userRole } = useRole();

  if (userRole === "Admin" || userRole === "Client" || userRole === "Tech") {
    return (
      <React.Fragment>
        <h1 className='table-header'>Dashboard</h1>
        <ListGroup className="left-pane">
          <h4>Quick Start Guide</h4>
            <ListGroupItem>
                1. <Link to='/registerBoard'>Register a New Board</Link> to create a unique PcbSn for the
                physical IoT device (board) you would like to use with the EDG Client Portal.
            </ListGroupItem>
            <ListGroupItem>
                2. Get the board&apos;s serial number (aka PcbSn). There is a copy link on the <Link to='/manageBoards'>Manage Boards</Link> pane
                for each PcbSn you have registered.
            </ListGroupItem>
            <ListGroupItem>
                    3. Reference the PcbSn corresponding to your physical IoT device at the appropriate location in that device&apos;s
                    software or firmware. That device will use its PcbSn to identify itself to the EDG Client Portal application.
            </ListGroupItem>
            <ListGroupItem>
                    4. Update your device&apos;s software or firmware to use the appropriate API endpoints to send data to
                    the EDG Client Portal. See the Reference Documents to the right for more information.
            </ListGroupItem>
            <ListGroupItem>
                5.  Power up your device and allow it to send data to the EDG Client Portal!
                View your data at the <Link to='/boardData'>Board Data</Link> page.
            </ListGroupItem>
        </ListGroup>

        <ListGroup className="right-pane">
          <h4>Reference Documents</h4>
          <ListGroupItem>
                    <a href='https://edglab.notion.site/API-Quick-Start-Guide-17cbd5973a90490da86d48e51c3155ae' target="_blank" rel="noreferrer">API User Documentation</a>
          </ListGroupItem>
          <ListGroupItem>
                    <a href='https://apidocs.edglab.com/' target="_blank" rel="noreferrer">API Endpoints Reference</a>
          </ListGroupItem>
        </ListGroup>
      </React.Fragment>
    );
  } else if (userRole == "ReadOnly") {
    return (
      <React.Fragment>
        <h1 className='table-header'>Dashboard</h1>
        <ListGroup>
            <ListGroupItem>
                <Link to='/boarddata'>Board Data</Link>
            </ListGroupItem>
            <ListGroupItem>
                <Link to='/manageBoards'>View Boards</Link>
            </ListGroupItem>
        </ListGroup>
      </React.Fragment>
    )
  } else if (userRole == "EndClient") {
    return (
        <React.Fragment>
            <h1 className='table-header'>Dashboard</h1>
            <ListGroup>
                <ListGroupItem>
                    <Link to='/boarddata'>Board Data</Link>
                </ListGroupItem>
            </ListGroup>
        </React.Fragment>
      )
  } else {
      // This "else" with a return is necessary because react needs to render the page before we have a value for userRole.
      return (
          <React.Fragment>
              <div>Loading...</div>
          </React.Fragment>
      )
  }
}
