import { React, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../stylesheets/custom.css';
import { useRole } from './hooks/useRole';

export function DataRangeDownload(props) {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [alertString, setAlertString] = useState("Please fill out all required fields.");
    
    const { userRole } = useRole();

    const appToken = localStorage.getItem("appToken");
    const token = localStorage.getItem("token");

    const flattenObject = (obj, flattenedObj = {}) => {
        Object.entries(obj).forEach(([key, value]) => {
            if (typeof value == 'object') {
                flattenedObj = { ...flattenedObj, ...flattenObject(value, flattenedObj) }
            } else if (flattenedObj.hasOwnProperty(key)) {
                let i = 1;
                while (flattenedObj.hasOwnProperty(key + i)) {
                    i++;
                }
                let newPropName = key + i;
                flattenedObj = { ...flattenedObj, ...{ [newPropName]: value } }
            } else {
                flattenedObj = { ...flattenedObj, ...{ [key]: value } }
            }
        })
        return flattenedObj;
    }

    const JSONToCSVConvertor = (jsonData, fileName) => {
        /* first create our flattened array holder */
        let flattenedJson = [];

        /* sort the input data by pcbModelNumber */
        jsonData = jsonData.sort((a, b) => {
            let comp = 0;
            if (a.pcbMn < b.pcbMn) {
                comp = -1;
            } else if (a.pcbMn > b.pcbMn) {
                comp = 1;
            }
            return comp;
        })

        /* unroll all objects into array style for every data entry */
        for (let i = 0; i < jsonData.length; i++) {
            /* spread the current object into our currRow holder, with a spare object */
            let currRow = { ...jsonData[i], ...{} };

            /* remove the userId and companyId */
            delete currRow.userId;
            delete currRow.companyId;

            let unrollDataObjects = ('dataRev' in currRow) ? (jsonData['dataRev'] === '1.0') : true;

            /* the currRow dataObjects must be sorted and mapped to unroll the rawData */
            /* if dataRev == 1.0 */
            if (unrollDataObjects) {
                currRow.dataObjects = currRow.dataObjects.sort((a, b) => {
                    let comp = 0;
                    if (a.objectEnum < b.objectEnum) {
                        comp--;
                    } else if (a.objectEnum > b.objectEnum) {
                        comp++;
                    }
                    return comp;
                }).map(ob => {
                    if (ob.isHidden === true && userRole !== 'Admin') {
                        return false;
                    }
                    delete ob.isHidden;
                    delete ob.objectEnum;
                    ob.rawData = ob.rawData.map(rd => {
                        return {
                            [rd.dataName]: rd.data
                        }
                    });
                    return ob;
                }).filter(ob => ob !== false);
            }

            /* remove the dataRev */
            delete currRow['dataRev'];

            let result = flattenObject(currRow);
            flattenedJson.push(result);
        }

        let arrData = typeof flattenedJson != 'object' ? JSON.parse(flattenedJson) : flattenedJson;

        let CSV = '';

        let headerRow;

        for (let i = 0; i < arrData.length; i++) {
            let row = "";
            let currHeaderRow = Object.keys(arrData[i]).map(k => {
                if (k === "timeSent") {
                    return "hardwareTimestamp";
                } else {
                    return k;
                }
            }).join(',');
            if (currHeaderRow !== headerRow) {
                const emptyRow = Object.keys(arrData[i]).map(key => '').join(',')
                row += emptyRow + '\r\n'
                row += currHeaderRow + '\r\n'
                headerRow = currHeaderRow
            }

            for (let index in arrData[i]) {
                let data = arrData[i][index];
                if (typeof data === 'object' && data !== null) {
                    data = "null";
                }
                row += '"' + data + '",';
            }

            CSV += row + '\r\n';
        }

        if (CSV === '') {
            setAlertString("Something went wrong.");
            setSuccess(false)
            return;
        } else {
            setSuccess(true)
        }

        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
        let link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const DownloadJSON = (data) => {
        JSONToCSVConvertor(data, props.fileName, props.showLabel);
    }

    const getDeviceIds = (accessToken) => {
        setLoading(true);

        // Limit to 4 weeks of hourly data (672 total entries)
        fetch(`app/devices`, {
            method: 'GET',
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Authorization": `Bearer ${accessToken}`
            }
        })
        .then(resp => {
            if (resp.ok) {
                return resp.json()
            } else {
                throw new Error(resp.status + " No data returned")
            }
        })
        .then((data) => {
            if (data.count > 0) {
                getData(accessToken, data.devicelist);
            } else {
                setSuccess(false);
                setAlertString("No devices found.");
            }
            
        })
        .then(setLoading(false))
        .catch(e => console.log(e));
    }

    const getData = (accessToken, devicelist) => {
        setLoading(true);

        // Limit is required, so limit to 10,100 rows of data.
        // TO DO: Currently we do not have a convenient way to retrieve unlimited data between two timestamps.
        // See related issue: https://github.com/Engineering-Design-Group-LLC/EDG_Client_Portal_sandbox/issues/390
        fetch(`api/boarddata/limits?notbefore=${props.timestampFrom}&until=${props.timestampTo}&offset=0&limit=10100`, {
            method: 'POST',
            body: JSON.stringify(
                devicelist
            ),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(resp => {
            if (resp.ok) {
                return resp.json()
            } else {
                throw new Error(resp.status + " No data returned")
            }
        })
        .then((data) => {
            if (data.length > 0) {
                DownloadJSON(data);
            } else {
                setSuccess(false);
                setAlertString("No data found for the specified range.");
            }
        })
        .then(setLoading(false))
        .catch(e => console.log(e));
    }

    // Instead of the button reloading the page with the Button's href attribute, we call this function.
    // This preserves state (versus reloading) and allows us to prepopulate the form fields.
    const reloadPage = () => {
        setAlertString("Please fill out all required fields.");
        setLoading(false);
        setSuccess(null);
    }

    if (success === true) {
        return (
            <div>
                <input id="buttonDownloadCsvRange" className={props.className} type="button" value={props.buttonText} onClick={() => getDeviceIds(appToken)} />
                <Modal isOpen={true}>
                    <ModalHeader>Download In Progress</ModalHeader>
                    <ModalBody>
                        <div className="bold">Time to download your CSV may vary. Please do not close this pop-up or browser tab until the download is complete. Once downloaded, find your CSV on your filesystem where your browser saves downloads.</div>
                    </ModalBody>
                    <ModalFooter>
                        <a href='/dataRangeSelect'>
                            <Button className="button-list btn downloadButton">Download another range?</Button></a>{' '}
                        <a href='/boarddata'>
                            <Button className="button-list" color="secondary">Return to Board Data</Button></a>
                    </ModalFooter>
                </Modal>
            </div>
        );
    } else if (success === false) {
        return (
            <div>
                <input id="buttonDownloadCsvRange" className={props.className} type="button" value={props.buttonText} onClick={() => getDeviceIds(appToken)} />
                <Modal isOpen={true}>
                    <ModalHeader>Something Went Wrong</ModalHeader>
                    <ModalBody>
                        <div className="bold">{`${alertString}`}</div>
                    </ModalBody>
                    <ModalFooter>
                        <a href='/dataRangeSelect'>
                            <Button className="button-list btn downloadButton" onClick={() => reloadPage()}>Try again?</Button></a>{' '}
                        <a href='/boarddata'>
                            <Button className="button-list" color="secondary">Return to Board Data</Button></a>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
   
    return (
        <input id="buttonDownloadCsvRange" className={props.className} type="button" value={props.buttonText} onClick={() => getDeviceIds(appToken)} />
    );
}
