import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Col, FormGroup, Label, Alert } from 'reactstrap';
import { Link } from "react-router-dom";
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';
import '../stylesheets/NavMenu.css';

export function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);
    const [success, setSuccess] = useState(null);
    const [verificationRequired, setVerificationRequired] = useState(false);
    const [alertString, setAlertString] = useState("Something went wrong.");

    useEffect(() => {
        localStorage.removeItem("appToken");
        localStorage.removeItem("token");
        setLoggedOut(true);
    }, [loggedOut]);

    const updateUsername = (e) => {
        setUsername(e.target.value);
    }
    const updatePassword = (e) => {
        setPassword(e.target.value);
    }

    const getTokens = (e) => {
        getAppToken(e);
        getJwt(e);
    }

    // TODO: Which client ID should be used here? This is a secret, so it seems it shoud not be hard-coded.
    const getAppToken = (e) => {
        let rkey = "my-" + Date.now() + "-key";
        e.preventDefault();
        // TODO: We are already advertising this client_id. But do we want to have it hardcoded here?
        fetch(`app/oauth/token`, {
            method: 'POST',
            body: JSON.stringify({
                grant_type: "password",
                client_id: "3777217A2BCDE467412EXAMPLEFORTESTING4E635266556A5811649E3B70371C",
                rkey: rkey,
                timestamp: 0
            }),
            headers: {
                "username": username,
                "password": password,
                "Content-type": "application/json; charset=UTF-8"
            },
        })
        .then(resp => {
            if (resp.status == 401) {
                // We throw an error to jump beyond the section which checks for a token.
                throw new Error("Login failed.");
            } else {
                return resp.json()
            }
        })
        .then(data => {
            if (!data.access_token) {
                localStorage.removeItem("appToken");
            } else {
                localStorage.setItem("appToken", data.access_token);
            }
        })
        .catch(e => console.log(e));
    }

    const getJwt = (e) => {
        setLoading(true);
        e.preventDefault();
        fetch(`api/login`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(resp => {
            setLoading(false);
            if (resp.status == 401) {
                setSuccess(false);
                resp.text().then((text) => {
                    setAlertString(text);
                });
                // We throw an error to jump beyond the section which checks for a token.
                throw new Error("Login failed.");
            } else if (resp.status == 428) {
                setVerificationRequired(true);
                resp.text().then((text) => {
                    setAlertString(text);
                });
                // We throw an error to jump beyond the section which checks for a token.
                throw new Error("Login failed.");
            } else {
                return resp.json()
            }
        })
        .then(data => {
            if (!data.token) {
                localStorage.removeItem("token");
                setSuccess(false);
            } else {
                localStorage.setItem("token", data.token);
                setSuccess(true)
            }
        })
        .catch(e => console.log(e));
    }

    if (verificationRequired === true) {
        return (
            <Alert className='alert-alignment' color='primary'>{`${alertString}`}
                <a className='alert-link nav-space' href='/'>Once you&apos;ve verified your username, you may login to your account.</a>
            </Alert>
        )
    }

    if(success === true) {
        return (
            <Alert className='alert-alignment' color='success'>
                Login successful.
                <a className='alert-link nav-space' href='/'>Go to dashboard?</a>
            </Alert>
        )
    } else if(success === false) {
        return (
            <Alert className='alert-alignment' color='danger'>{`${alertString}`}
                <Button color='link' className='alert-link' href='/'>Try again?</Button>
            </Alert>
        )
    }

    if(loading === true) {
        return (
            <span>Loading...</span>
        )
    }
    
    return (
        <Col sm="12" md={{ size: 6, offset: 3 }}>
            <div className='modal-head modal-header'>
                <h4 className='logo-text'>Login to your data marketplace</h4>
            </div>
            <Form onSubmit={(e) => getTokens(e)}>
                <FormGroup className='form-space' row>
                    <Label for="username" sm={3}>Email</Label>
                    <Col sm={9}>
                        <Input onChange={e => updateUsername(e)} type="username" name="username" id="username" />
                    </Col>
                </FormGroup>
                <FormGroup className='form-space' row>
                    <Label for="password" sm={3}>Password</Label>
                    <Col sm={9}>
                        <Input onChange={e => updatePassword(e)} type="password" name="password" id="password" />
                    </Col>
                </FormGroup>
                <div className='btn-toolbar justify-content-center'>
                    <input value="Login" type="submit" className="form-space btn downloadButton" />
                </div>
            </Form>
            <hr className='link-footer-hr' />
            <span className='link-footer-content'>
                <Link to='/signup'>Create your free account</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
                <Link to='/passwordRequest'>Forgot your password?</Link>
            </span>
        </Col>
    )
}