import { useState, useEffect } from 'react';

export const userRoles = { "Admin": 0, "Client": 1, "ReadOnly": 2, "Tech": 3, "EndClient": 4, "GuestClient": 5 };

export function useRole() {
    const [userRole, setRole] = useState("");
    const [username, setUsername] = useState("");
    const token = localStorage.getItem("token");
    
    useEffect(() => {
        if(token) {
            var base64Payload = token.split('.')[1];
        
            var payload = window.atob(base64Payload);
        
            var payloadValues = payload.valueOf();
            var parsedPayload = JSON.parse(payloadValues);
            var now = new Date();
            now = now.getTime() / 1000;
        
            if(parsedPayload.exp > now) {
              setRole(parsedPayload.UserRole);
              setUsername(parsedPayload.UserName);
            } 
          }
    }, [token]);

    return { userRole, username };
}