import React from 'react';
import '../stylesheets/custom.css';
import '../stylesheets/NavMenu.css';

export function Footer() {

    return (
        <nav id="footer" className="navbar fixed-bottom d-flex justify-content-center">
            <div className='text-light footer-text nav-space'>{`Questions? Email us directly:  `} 
                <a className='email-link' href="mailto:support@edglab.com?subject=Questions from Web App" alt="#">{"support@edglab.com"}</a>
                {` | version 2.1.4`}
            </div>
        </nav>
    );
}