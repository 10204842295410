import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useRole } from './hooks/useRole';

export function QuickView(props) {
    const [modal, setModal] = useState(false);
    const {userRole} = useRole();
    
    const toggle = () => setModal(!modal);

    const boardDataObjects = () => {
        let boardDataObjects = props.board.dataObjects || [];
        if (userRole === 'Client') {
            boardDataObjects = boardDataObjects.filter(ob => ob.isHidden !== 'true')
        }
        return boardDataObjects
    }

  const boardDataList = props.board.dataList || {};

  const rowOnlyIfData = (dataTitle, dataStr) => {
    if (dataStr != null && dataStr != "")
      return (
        <tr className='d-flex'>
          <th className="col-sm-6">{dataTitle}</th>
          <td className="col-sm-6">{dataStr}</td>
        </tr>
      )
  }

  if (props.board.dataRev == "1.1") {
    return (
      <React.Fragment>
        <Button className='table-button' color="primary" onClick={toggle}>QuickView</Button>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Board Data</ModalHeader>
          <ModalBody>
            <table className="table">
              <tbody>
                {rowOnlyIfData("IdImpDevice", props.board.idImpDevice)}
                {rowOnlyIfData("Pcb MN", props.board.pcbMn)}
                {rowOnlyIfData("Pcb SN", props.board.pcbSn)}
                {rowOnlyIfData("Pcb Rev", props.board.pcbRev)}
              </tbody>
            </table>
            <table className="table">
              <thead><tr><td>Data List</td></tr></thead>
              <tbody>
                {Object.keys(boardDataList).map((dkey, index_rd) => {
                  return (
                    <tr className='d-flex' key={index_rd}>
                      <th className="col-sm-6">{dkey}</th>
                      <td className="col-sm-6">{boardDataList[dkey]}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button className='button-list' color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  } else {
  return (
    <div>
      <Button className='table-button' color="primary" onClick={toggle}>QuickView</Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Board Data</ModalHeader>
        <ModalBody>
          <table className="table">
            <tbody>
              {rowOnlyIfData("IdImpDevice", props.board.idImpDevice)}
              {rowOnlyIfData("Pcb MN", props.board.pcbMn)}
              {rowOnlyIfData("Pcb SN", props.board.pcbSn)}
              {rowOnlyIfData("Pcb Rev", props.board.pcbRev)}
            </tbody>
          </table>
          {boardDataObjects()
            .map((ob, index) => {
              return (
                <React.Fragment key={index}>
                  <table className="table">
                    <thead><tr><td>Data Objects</td></tr></thead>
                    <tbody>
                      <tr className='d-flex'>
                        <th className="col-sm-6">Object Type</th>
                        <td className="col-sm-6">{ob.objectType}</td>
                      </tr>
                      <tr className='d-flex'>
                        <th className="col-sm-6">Object Mnfg</th>
                        <td className="col-sm-6">{ob.objectMnfg}</td>
                      </tr>
                      <tr className='d-flex'>
                        <th className="col-sm-6">Object MN</th>
                        <td className="col-sm-6">{ob.objectMn}</td>
                      </tr>
                      <tr className='d-flex'>
                        <th className="col-sm-6">Object SN</th>
                        <td className="col-sm-6">{ob.objectSn}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table">
                    <thead><tr><td>Raw Data</td></tr></thead>
                    <tbody>
                      {
                        ob.rawData.map((rd, index_rd) => {
                          return (
                            <React.Fragment key={index_rd}>
                              <tr className='d-flex'>
                                <th className="col-sm-6">Data Type</th>
                                <td className="col-sm-6">{rd.dataType}</td>
                              </tr>
                              <tr className='d-flex'>
                                <th className="col-sm-6">Data</th>
                                <td className="col-sm-6">{rd.data}</td>
                              </tr>
                              <tr className='d-flex'>
                                <th className="col-sm-6">Data Name</th>
                                <td className="col-sm-6">{rd.dataName}</td>
                              </tr>
                            </React.Fragment>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </React.Fragment>
              )
            })}
        </ModalBody>
        <ModalFooter>
          <Button className='button-list' color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
    )
  }
}
