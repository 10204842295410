import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Col, FormGroup, Label, Alert, DropdownMenu, DropdownToggle, DropdownItem, Dropdown } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/forms.css';
import '../stylesheets/buttons.css';
import '../stylesheets/alert.css';
import { userRoles, useRole } from './hooks/useRole';
import { checkPermission, UserRoleComponent } from './UserRoleComponent';
import { LevelWall } from './LevelWallComponent';

export function RegisterUserPage() {
    const [allCompanies, setAllCompanies] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allUsersReady, setAllUsersReady] = useState(null);
    const [companyId, setCompanyId] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [usernameRegister, setUsernameRegister] = useState("");
    const [showAlert, setShowAlert] = useState(null);
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [validated, setValidated] = useState(null);
    const [role, setRole] = useState(4);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneSubmit, setPhoneSubmit] = useState("");
    const [phoneDisplay, setPhoneDisplay] = useState("");
    const [alertString, setAlertString] = useState("Please fill out all required fields.");
    const token = localStorage.getItem("token");
    const [levelWallUp, setLevelWallUp] = useState(false);
    const { userRole, username } = useRole();

    useEffect(() => {
        fetch(`api/companies`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => setAllCompanies(data))
        .catch(e => console.log(e));

        fetch(`api/users/`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => {
            setAllUsers(data);
            setAllUsersReady(true);
        })
            .catch(e => console.log(e));

        if (document.getElementById(role) != null) {
            // On 'Try again?', we set the appropriate value only after the page has rendered.
            // This is prompted by a change in success in the reloadPage() function.
            document.getElementById(role).checked = true;
        }
    }, [token, success]);

    const updateUsernameRegister = (e) => {
        setUsernameRegister(e.target.value);

        // The dropdown box of company names is only displayed for admin. So, for other user roles,
        // we must assign the companyName and companyId in the background.
        if (!checkPermission(0, userRole) && allUsersReady) {
            var firstCompany = allUsers[0];

            // TODO: Debug to display companyId and companyName of the user who is signed in
            //console.log("allUsers[0] info: " + firstCompany, firstCompany.companyId, firstCompany.companyName);

            setCompanyId(firstCompany.companyId);
            setCompanyName(firstCompany.companyName);
        }
    }

    const updatePassword = (e) => {
        setPassword(e.target.value);
        checkPassword(e);
    }

    const checkPassword = (e) => {
        if (document.getElementById('password').value == document.getElementById('passwordcheck').value) {
            document.getElementById('passwordMatchMessage').style.color = 'green';
            document.getElementById('passwordMatchMessage').innerHTML = 'Passwords match';
            return true;
        }
        else {
            document.getElementById('passwordMatchMessage').style.color = 'red';
            document.getElementById('passwordMatchMessage').innerHTML = 'Passwords do not match';
            return false;
        }

    }

    const updateRole = (e) => {
        setRole(e.target.value);
    }

    const updateFirstname = (e) => {
        setFirstName(e.target.value);
    }

    const updateLastname = (e) => {
        setLastName(e.target.value);
    }

    const updatePhone = (e) => {
        var onlyNumbers = e.target.value.replace(/\D/g, "");
        setPhoneSubmit('+1' + onlyNumbers);
        setPhoneDisplay(e.target.value);
    }

    const handleSelectedCompany = (c) => {
        setCompanyId(c.id);
        setCompanyName(c.companyName);
    }

    const registerUser = (e) => {
        setLoading(true);
        e.preventDefault();
        if(username === "" || password === "" || companyName === "") {
            setSuccess(false);
        } else {
            fetch(`api/users`, {
                method: 'POST',
                body: JSON.stringify({
                    username: usernameRegister,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phoneSubmit,
                    userRole: Number(role),
                    companyId: companyId,
                    companyName: companyName,
                    AddedBy: username
                }),
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })
            .then(resp => {
                setLoading(false);
                if (resp.status == 403) {
                    setSuccess(false);
                    resp.text().then((text) => {
                        setAlertString(text);
                        if (text.search("Free") >= 0) {
                            setLevelWallUp(true);
                        }
                    });
                } else {
                    if (resp.ok) {
                        setSuccess(true);
                    } else {
                        setSuccess(false);
                    }
                }
            })
            .catch(e => console.log(e));
            setShowAlert(true);
        }
    }

    const levelWallToggle = () => {
        setLevelWallUp(!levelWallUp);
    }

    const createAccount = async (e) => {
        if (checkPassword(e)) {
            await registerUser(e);
        }
        else {
            setSuccess(false);
            setAlertString("Password and Confirm Password fields do not match.")
        }
    }

    const onToggle = () => setDropdownOpen(!dropdownOpen);

    // Instead of the button reloading the page with the Button's href attribute, we call this function.
    // This preserves state (versus reloading) and allows us to prepopulate the form fields.
    const reloadPage = () => {
        setAlertString("Please fill out all required fields.");
        setLoading(false);
        setShowAlert(false);
        setSuccess(null);
        setValidated(null);
    }

    if(success === true) {
        return (
            <Alert className='alert-alignment' color="success">{`Successfully assigned new user to ${companyName.indexOf("auto_") == 0 ? "your company" : companyName}.`}
                <div>
                    <Button color='link' className='alert-link' href='/registerUser'>Add another user</Button>
                    <Button color='link' className='alert-link' href='/viewUsers'>View all users</Button>
                </div>
            </Alert>
        )
    } else if(success === false) {
        return (
            <div>
                <Alert className='alert-alignment' color="danger">{`${alertString}`}
                    <Button color='link' className="alert-link" onClick={() => reloadPage()}>Try again?</Button>
                </Alert>
                <LevelWall
                    wallOpen={levelWallUp}
                    wallToggle={levelWallToggle}
                    wallMessage={alertString}
                    declineLink="/viewUsers"
                />
            </div>
        )
    }

    if (loading === true) {
        return (
            <span>Loading...</span>
        )
    }
    
    return (
        <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Form onSubmit={(e) => createAccount(e)} className={showAlert ? 'hidden' : ''}>
                <h1 className="table-header">Register Someone New</h1>
                {checkPermission(0, userRole) ?
                    <FormGroup>
                        <Dropdown isOpen={dropdownOpen} toggle={onToggle}>
                            <DropdownToggle className='primaryColor button-list' caret>
                                Select Company
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>Companies</DropdownItem>
                                {
                                    allCompanies.map(c => {
                                        return (
                                            <div key={c.id}>
                                                <DropdownItem onClick={() => handleSelectedCompany(c)}>{c.companyName}</DropdownItem>
                                            </div>
                                    )})
                                }
                            </DropdownMenu>
                        </Dropdown>
                        <h5 className={companyName === "" ? 'hidden' : 'space'}>{`Selected company: ${companyName}`}</h5>
                    </FormGroup>
                    :
                    <FormGroup>
                        <input type="hidden" name="companyName" id="companyName" value={companyName} />
                    </FormGroup>
                }
                <FormGroup row>
                    <Label for="usernameRegister" sm={2}>Email</Label>
                    <Col sm={10}>
                        <Input onChange={e => updateUsernameRegister(e)} type="text" name="usernameRegister" id="usernameRegister" value={usernameRegister} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="password" sm={2}>Password</Label>
                    <Col sm={10}>
                        <Input onChange={e => updatePassword(e)} type="password" name="password" id="password" minLength="7" required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="passwordcheck" sm={2}>Confirm Password</Label>
                    <Col sm={10}>
                        <Input onChange={e => checkPassword(e)} type="password" name="passwordcheck" id="passwordcheck" required />
                        <span id='passwordMatchMessage'></span>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="firstname" sm={2}>First Name <em>(optional)</em></Label>
                    <Col sm={10}>
                        <Input onChange={e => updateFirstname(e)} type="text" name="firstname" id="firstname" value={firstName} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="lastname" sm={2}>Last Name <em>(optional)</em></Label>
                    <Col sm={10}>
                        <Input onChange={e => updateLastname(e)} type="text" name="lastname" id="lastname" value={lastName} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="phoneDisplay" sm={2}>Phone Number <em>(optional)</em></Label>
                    <Col sm={10}>
                        <Input onChange={e => updatePhone(e)} type="tel" name="phoneDisplay" id="phoneDisplay" value={phoneDisplay} minLength="10"/>
                    </Col>
                </FormGroup>
                <h5 className='form-header'>User Role:</h5>
                <Col>

                    <UserRoleComponent userRole={userRole} setDefault={role == 4 ? true : false} onChange={e => updateRole(e)} />

                    <div className='btn-toolbar justify-content-center'>
                        <input value="Register User" type="submit" className="button-list btn downloadButton" />
                        <Button className='button-list' color="secondary" href='/viewUsers'>Cancel</Button>
                    </div>
                </Col>
            </Form>
        </Col>
    )
}
