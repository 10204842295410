import React from 'react';
import { Button } from 'reactstrap';
import '../stylesheets/custom.css';

export function Pagination(props) {

    return (
        <nav>
            <ul className="pagination justify-content-center">
                <li className={props.currentPage === 1 || props.selectedAll ? "page-item disabled" : "page-item"}>
                    <Button className="page-link" 
                    onClick={props.previous} 
                    tabIndex="-1" 
                    aria-disabled={props.currentPage === 1 || props.selectedAll ? "false" : "true"}>Previous</Button>
                </li>
                <li className={props.lastPage || props.selectedAll ? "page-item disabled" : "page-item"}>
                    <Button className="page-link" 
                    onClick={props.next}
                    tabIndex="-1" 
                    aria-disabled={props.lastPage || props.selectedAll ? "false" : "true"}>Next</Button>
                </li>
            </ul>
        </nav>
    );
}
