import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import '../stylesheets/custom.css';
import { Footer } from './Footer';

export function Layout(props) {
  
    return (
      <div>
        <NavMenu />
        <Container className='page-space' fluid>
          {props.children}
        </Container>
        <Footer />
      </div>
    );
}
