import React, { useState } from 'react'; 
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/buttons.css';


export function ConfirmDelete({ deleteSelected, deleteModal, deleteToggle,
  buttonLabel, deleteButtonClass, deleteMessage, btnDisabled }) {
    if (btnDisabled == null) btnDisabled = false;
  return (
      <div>
          <Button id="button_delete_board" className={deleteButtonClass} color="danger" onClick={deleteToggle} disabled={btnDisabled}>{buttonLabel}</Button>
          <Modal isOpen={deleteModal} toggle={deleteToggle}>
              <ModalHeader toggle={deleteToggle}>Are you sure you want to delete?</ModalHeader>
              <ModalBody>
                  {`This will permanently delete ${deleteMessage}`}
              </ModalBody>
              <ModalFooter>
                  <Button className="button-list" color="danger" onClick={deleteSelected}>Yes</Button>{' '}
                  <Button className="button-list" color="secondary" onClick={deleteToggle}>No</Button>
              </ModalFooter>
          </Modal>
      </div>
  );
}