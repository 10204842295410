import { useState } from 'react';

export function useDeleted() {
    const [deleted, setDeleted] = useState(false);

    const isDeleted = () => {
        setDeleted(true);
    }

    const isNotDeleted = () => {
        setDeleted(null);
    }

    return { deleted, setDeleted, isDeleted, isNotDeleted };
}