import { useState } from 'react';

export function useSelectedBoards() {
    const [selectedBoards, setSelectedBoards] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const selectedAll = (boards) => {
        setSelectedBoards(boards);
        setRowCount(boards.length);
    }
    const addToSelectedBoards = (b) => {
        setSelectedBoards([...selectedBoards, b]);
        setRowCount(selectedBoards.length + 1);
    }
    const removeFromSelectedBoards = (b) => {
        setSelectedBoards(selectedBoards.filter(board => board !== b));
        setRowCount(selectedBoards.length - 1);
    }

    return {  selectedBoards, addToSelectedBoards, removeFromSelectedBoards, selectedAll, rowCount };
}