import React from 'react';
import '../stylesheets/custom.css';
import { useRole } from './hooks/useRole';

export function Download(props) {
    const {userRole} = useRole();
    const btnDisabled = (props.btnDisabled == null) ? false : props.btnDisabled;

    const flattenObject = (obj, flattenedObj = {}) => {
        Object.entries(obj).forEach(([key, value]) => {
            if (typeof value == 'object') {
                flattenedObj = {...flattenedObj, ...flattenObject(value, flattenedObj)}
            } else if (flattenedObj.hasOwnProperty(key)) {
                let i = 1;
                while (flattenedObj.hasOwnProperty(key + i)) {
                    i++;
                }
                let newPropName = key + i;
                flattenedObj = {...flattenedObj, ...{[newPropName]: value}}
            } else {
                flattenedObj = {...flattenedObj, ...{[key]: value}}
            }
        })
        return flattenedObj;
    }

  const JSONToCSVConvertor = (jsonData, fileName) => {
      /* first create our flattened array holder */
        let flattenedJson = [];

      /* sort the input data by pcbModelNumber */
        jsonData = jsonData.sort((a, b) => {
            let comp = 0;
            if (a.pcbMn < b.pcbMn) {
                comp = -1;
            } else if (a.pcbMn > b.pcbMn) {
                comp = 1;
            }
            return comp;
        })

      /* unroll all objects into array style for every data entry */
    for (let i = 0; i < jsonData.length; i++) {
      /* spread the current object into our currRow holder, with a spare object */
      let currRow = { ...jsonData[i], ...{} };

      /* remove the userId and companyId */
      delete currRow.userId;
      delete currRow.companyId;

      let unrollDataObjects = ('dataRev' in currRow) ? (jsonData['dataRev'] === '1.0') : true;

      /* the currRow dataObjects must be sorted and mapped to unroll the rawData */
      /* if dataRev == 1.0 */
      if (unrollDataObjects) {
        currRow.dataObjects = currRow.dataObjects.sort((a, b) => {
          let comp = 0;
          if (a.objectEnum < b.objectEnum) {
            comp--;
          } else if (a.objectEnum > b.objectEnum) {
            comp++;
          }
          return comp;
        }).map(ob => {
          if (ob.isHidden === true && userRole !== 'Admin') {
            return false;
          }
          delete ob.isHidden;
          delete ob.objectEnum;
          ob.rawData = ob.rawData.map(rd => {
            return {
              [rd.dataName]: rd.data
            }
          });
          return ob;
        }).filter(ob => ob !== false);
      }

      /* remove the dataRev */
      delete currRow['dataRev'];

      let result = flattenObject(currRow);
      flattenedJson.push(result);
    }

        let arrData = typeof flattenedJson != 'object' ? JSON.parse(flattenedJson) : flattenedJson;

        let CSV = ''; 

        let headerRow;
    
        for (let i = 0; i < arrData.length; i++) {
            let row = "";
            let currHeaderRow = Object.keys(arrData[i]).map(k => {
                if(k === "timeSent") {
                    return "hardwareTimestamp";
                } else {
                    return k;
                }
            }).join(',');
            if (currHeaderRow !== headerRow) {
                const emptyRow = Object.keys(arrData[i]).map(key => '').join(',')
                row += emptyRow + '\r\n'
                row += currHeaderRow + '\r\n'
                headerRow = currHeaderRow
            }
  
            for (let index in arrData[i]) {
                let data = arrData[i][index];
                if(typeof data === 'object' && data !== null){
                    data = "null";
                }
                row += '"' + data + '",';
            }
    
            CSV += row + '\r\n';
        }
    
        if (CSV === '') {        
            alert("Invalid data");
            return;
        }   

        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
        let link = document.createElement("a");    
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const DownloadJSON = () => {
        JSONToCSVConvertor(props.jsonData, props.fileName, props.showLabel);
    }

    return (
        <input id="buttonDownloadCsv" className={props.className} type="button" value={props.buttonText} onClick={DownloadJSON} disabled={btnDisabled} />
    );
}
