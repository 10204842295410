import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Col, FormGroup, Label, Alert } from 'reactstrap';
import { Link } from "react-router-dom";
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';
import '../stylesheets/NavMenu.css';

export function PasswordRequestPage() {
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);
    const [alertString, setAlertString] = useState("Something went wrong.");
    const [emailSent, setEmailSent] = useState(null);
    const [primary, setPrimary] = useState(false);
    
    useEffect(() => {
        localStorage.removeItem("appToken");
        localStorage.removeItem("token");
        setLoggedOut(true);
    }, [loggedOut]);

    const updateUsername = (e) => {
        setUsername(e.target.value);
    }

    const checkUsername = () => {
        // Make sure the username is in email format
        if (document.getElementById('username').value.indexOf("@") >= 0) {
            return true;
        } else {
            return false;
        }
    }

    const emailUser = async (e) => {
        setLoading(true);
        setEmailSent(false);
        setPrimary(false);
        await fetch('app/passresetrequest', {
            method: 'POST',
            body: JSON.stringify({
                Username: username
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(resp => {
            if (resp.status == 428) {
                setEmailSent(true);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            } else if (resp.status == 403) {
                setEmailSent(false);
                setPrimary(true);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            } else if (resp.status == 401) {
                setEmailSent(false);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            } else if (resp.status == 400) {
                setEmailSent(false);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            } else {
                setEmailSent(false);
            }
            setLoading(false);
        })
        .catch(e => console.log(e));
    }

    const emailInstructions = async (e) => {
        if (checkUsername()) {
            await emailUser(e);
        }
        else {
            setEmailSent(false);
            setAlertString("The username entered must be a valid email address.")
        }
    }

    // Instead of the button reloading the page with the Button's href attribute, we call this function.
    // This preserves state (versus reloading) and allows us to prepopulate the form fields.
    const reloadPage = () => {
        setEmailSent(null);
    }

    if(loading === true) {
        return (
            <span>Loading...</span>
        )
    }

    if (emailSent === true) {
        return (
            <Alert className='alert-alignment' color='primary'>{`${alertString}`}
                <a className='alert-link nav-space' href='/'>Go to login page?</a>
            </Alert>
        )
    } else if (emailSent === false) {
        return (
            <Alert className='alert-alignment' color={primary ? 'primary' : 'danger'}>{`${alertString}`}
                <Button color='link' className='alert-link' onClick={() => reloadPage()}>Try again?</Button>
            </Alert>
        )
    }
    
    return (
        <Col sm="12" md={{ size: 6, offset: 3 }}>
            <div className='modal-head modal-header'>
                <h4 className='logo-text'>Forgot your password?</h4>
            </div>
            <Form onSubmit={(e) => emailInstructions(e)}>
                <FormGroup className='form-space' row>
                    <Label for="username" sm={3}>Email</Label>
                    <Col sm={9}>
                        <Input onChange={e => updateUsername(e)} type="username" name="username" id="username" value={username} required />
                    </Col>
                </FormGroup>
                <div className='btn-toolbar justify-content-center'>
                    <input value="Request password reset" type="submit" className="form-space btn downloadButton" />
                </div>
            </Form>
            <hr className='link-footer-hr' />
            <span className='link-footer-content'>
                <Link to='/login'>Return to login page</Link>
            </span>
        </Col>
    )

}