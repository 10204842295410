import { React, useEffect, useState } from 'react';
import { Form, Input, Button, Col, FormGroup, Label, Alert, DropdownMenu, DropdownToggle, DropdownItem, Dropdown } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/forms.css';
import '../stylesheets/buttons.css';
import '../stylesheets/alert.css';
import { DataRangeDownload } from './DataRangeDownload';

export function DataRangeSelectPage() {
    const [showAlert, setShowAlert] = useState(null);
    const [timestampFrom, setTimestampFrom] = useState(Math.round((Date.now() / 1000) - 2592000)); // 30 days is equivalent to 2592000 seconds
    const [timestampTo, setTimestampTo] = useState(Math.round(Date.now() / 1000));

    // We can test against this RegExp to verify the inputs are integer only
    var reg = new RegExp('^[0-9]*$');

    // Convert timestampTo to UTC
    var toConvert = new Date(timestampTo * 1000);
    const [toMon, setToMon] = useState(toConvert.getUTCMonth() + 1);
    const [toDate, setToDate] = useState(toConvert.getUTCDate());
    const [toYear, setToYear] = useState(toConvert.getUTCFullYear());
    const [toHrs, setToHrs] = useState(toConvert.getUTCHours());
    const [toMin, setToMin] = useState(toConvert.getUTCMinutes());
    const [toSec, setToSec] = useState(toConvert.getUTCSeconds());

    // Convert timestampFrom to UTC
    var fromConvert = new Date(timestampFrom * 1000);
    const [fromMon, setFromMon] = useState(fromConvert.getUTCMonth() + 1);
    const [fromDate, setFromDate] = useState(fromConvert.getUTCDate());
    const [fromYear, setFromYear] = useState(fromConvert.getUTCFullYear());
    const [fromHrs, setFromHrs] = useState(fromConvert.getUTCHours());
    const [fromMin, setFromMin] = useState(fromConvert.getUTCMinutes());
    const [fromSec, setFromSec] = useState(fromConvert.getUTCSeconds());

    useEffect(() => {
        fromChecks();
        toChecks();
        checkTimestamps();
    }, [timestampFrom, timestampTo, fromMon, fromDate, fromYear, fromHrs, fromMin, fromSec, toMon, toDate, toYear, toHrs, toMin, toSec]);

    const toChecks = () => {
        var utcToMilli = Date.UTC(toYear, toMon - 1, toDate, toHrs, toMin, toSec)
        setTimestampTo(utcToMilli / 1000);
    }

    const fromChecks = () => {
        var utcFromMilli = Date.UTC(fromYear, fromMon - 1, fromDate, fromHrs, fromMin, fromSec)
        setTimestampFrom(utcFromMilli / 1000);
    }

    const checkTimestamps = () => {
        // Check "From" fields
        if (fromYear == null || fromMon == null || fromDate == null || fromHrs == null || fromMin == null || fromSec == null) {
            document.getElementById('timestampMessage').style.color = 'red';
            document.getElementById("buttonDownloadCsvRange").setAttribute("disabled", "");
            document.getElementById('timestampMessage').innerHTML = 'Please specify all fields for the <em>From</em> timestsamp';
        } else if (fromYear == "" || fromMon == "" || fromDate == "" || fromHrs == "" || fromMin == "" || fromSec == "") {
            document.getElementById('timestampMessage').style.color = 'red';
            document.getElementById("buttonDownloadCsvRange").setAttribute("disabled", "");
            document.getElementById('timestampMessage').innerHTML = 'Please specify all fields for the <em>From</em> timestsamp';
        } else if (!reg.test(document.getElementById('fromYear').value) ||
            !reg.test(document.getElementById('fromMon').value) ||
            !reg.test(document.getElementById('fromDate').value) ||
            !reg.test(document.getElementById('fromHrs').value) ||
            !reg.test(document.getElementById('fromMin').value) ||
            !reg.test(document.getElementById('fromSec').value)) {
                document.getElementById('timestampMessage').style.color = 'red';
                document.getElementById("buttonDownloadCsvRange").setAttribute("disabled", "");
                document.getElementById('timestampMessage').innerHTML = 'Change your <em>From</em> timestamp to use integers only';
        // Check "To" fields
        } else if (toYear == null || toMon == null || toDate == null || toHrs == null || toMin == null || toSec == null) {
            document.getElementById('timestampMessage').style.color = 'red';
            document.getElementById("buttonDownloadCsvRange").setAttribute("disabled", "");
            document.getElementById('timestampMessage').innerHTML = 'Please specify all fields for the <em>To</em> timestsamp';
        } else if (toYear == "" || toMon == "" || toDate == "" || toHrs == "" || toMin == "" || toSec == "") {
            document.getElementById('timestampMessage').style.color = 'red';
            document.getElementById("buttonDownloadCsvRange").setAttribute("disabled", "");
            document.getElementById('timestampMessage').innerHTML = 'Please specify all fields for the <em>To</em> timestsamp';
        } else if (!reg.test(document.getElementById('toYear').value) ||
            !reg.test(document.getElementById('toMon').value) ||
            !reg.test(document.getElementById('toDate').value) ||
            !reg.test(document.getElementById('toHrs').value) ||
            !reg.test(document.getElementById('toMin').value) ||
            !reg.test(document.getElementById('toSec').value)) {
                document.getElementById('timestampMessage').style.color = 'red';
                document.getElementById("buttonDownloadCsvRange").setAttribute("disabled", "");
                document.getElementById('timestampMessage').innerHTML = 'Change your <em>To</em> timestamp to use integers only';
        // Check that dates do not preceed 1970 (earliest allowed epoch date)
        } else if (toYear < 1970 || fromYear < 1970) {
            document.getElementById('timestampMessage').style.color = 'red';
            document.getElementById("buttonDownloadCsvRange").setAttribute("disabled", "");
            document.getElementById('timestampMessage').innerHTML = 'The year fields must not be earlier than 1970.';
        // Check validity of completed epoch fields (easier to check epoch than UTC)
        } else if (timestampFrom == timestampTo) {
            document.getElementById('timestampMessage').style.color = 'red';
            document.getElementById("buttonDownloadCsvRange").setAttribute("disabled", "");
            document.getElementById('timestampMessage').innerHTML = 'Invalid timestamp range';
        } else if (timestampFrom >= timestampTo) {
            document.getElementById('timestampMessage').style.color = 'red';
            document.getElementById("buttonDownloadCsvRange").setAttribute("disabled", "");
            document.getElementById('timestampMessage').innerHTML = 'Invalid timestamp range';
        } else if ((timestampTo - timestampFrom) > 2592000) {
            document.getElementById('timestampMessage').style.color = '#FFAC1C';
            document.getElementById("buttonDownloadCsvRange").removeAttribute("disabled", "");
            document.getElementById('timestampMessage').innerHTML = 'Range exceeds 30 days. Your browser may have issues downloading the CSV.';
        } else {
            document.getElementById('timestampMessage').style.color = 'green';
            document.getElementById("buttonDownloadCsvRange").removeAttribute("disabled", "");
            document.getElementById('timestampMessage').innerHTML = 'Timestamps are valid';
        }
    }

    return (
        <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Form className={showAlert ? 'hidden' : ''}>
                <h1 className="table-header">Select a Range of Data</h1>
                <p>
                    Data downloaded will use the timestamp the data was received by the EDG Client Portal. The default values span the last 30 days. For large ranges, multiple CSV downloads are recommended.
                </p>

                <FormGroup row>
                    <Label for="utcFrom" sm={3}>From (UTC)</Label>
                    <Col sm={9}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Mon.</td>
                                    <td></td>
                                    <td>Day</td>
                                    <td></td>
                                    <td>Yr.</td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>Hr.</td>
                                    <td></td>
                                    <td>Min.</td>
                                    <td></td>
                                    <td>Sec.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <Input onChange={e => setFromMon(e.target.value)}
                                            type="text"
                                            name="fromMon"
                                            id="fromMon"
                                            value={fromMon}
                                            maxLength="2"
                                            required />
                                    </td>
                                    <td>&nbsp;/&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setFromDate(e.target.value)}
                                            type="text"
                                            name="fromDate"
                                            id="fromDate"
                                            value={fromDate}
                                            maxLength="2"
                                            required />
                                    </td>
                                    <td>&nbsp;/&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setFromYear(e.target.value)}
                                            type="text"
                                            name="fromYear"
                                            id="fromYear"
                                            value={fromYear}
                                            maxLength="4"
                                            required />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setFromHrs(e.target.value)}
                                            type="text"
                                            name="fromHrs"
                                            id="fromHrs"
                                            value={fromHrs}
                                            maxLength="2"
                                            required />
                                    </td>
                                    <td>&nbsp;:&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setFromMin(e.target.value)}
                                            type="text"
                                            name="fromMin"
                                            id="fromMin"
                                            value={fromMin}
                                            maxLength="2"
                                            required />
                                    </td>
                                    <td>&nbsp;:&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setFromSec(e.target.value)}
                                            type="text"
                                            name="fromSec"
                                            id="fromSec"
                                            value={fromSec}
                                            maxLength="2"
                                            required />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="utcTo" sm={3}>To (UTC)</Label>
                    <Col sm={9}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Mon.</td>
                                    <td></td>
                                    <td>Day</td>
                                    <td></td>
                                    <td>Yr.</td>
                                    <td></td>
                                    <td>Hr.</td>
                                    <td></td>
                                    <td>Min.</td>
                                    <td></td>
                                    <td>Sec.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <Input onChange={e => setToMon(e.target.value)}
                                            type="text"
                                            name="toMon"
                                            id="toMon"
                                            value={toMon}
                                            maxLength="2"
                                            required />
                                    </td>
                                    <td>&nbsp;/&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setToDate(e.target.value)}
                                            type="text"
                                            name="toDate"
                                            id="toDate"
                                            value={toDate}
                                            maxLength="2"
                                            required />

                                    </td>
                                    <td>&nbsp;/&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setToYear(e.target.value)}
                                            type="text"
                                            name="toYear"
                                            id="toYear"
                                            value={toYear}
                                            maxLength="4"
                                            required />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setToHrs(e.target.value)}
                                            type="text"
                                            name="toHrs"
                                            id="toHrs"
                                            value={toHrs}
                                            maxLength="2"
                                            required />
                                    </td>
                                    <td>&nbsp;:&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setToMin(e.target.value)}
                                            type="text"
                                            name="toMin"
                                            id="toMin"
                                            value={toMin}
                                            maxLength="2"
                                            required />
                                    </td>
                                    <td>&nbsp;:&nbsp;</td>
                                    <td>
                                        <Input onChange={e => setToSec(e.target.value)}
                                            type="text"
                                            name="toSec"
                                            id="toSec"
                                            value={toSec}
                                            maxLength="2"
                                            required />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span id='timestampMessage'></span>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="epochFrom" sm={3}>From (epoch)</Label>
                    <Col sm={9} className="presetData">
                        {timestampFrom != null ? timestampFrom : "(not specified)"}
                        <Input type="hidden"
                            name="timestampFrom"
                            id="timestampFrom"
                            value={timestampFrom}
                            maxLength="10"
                            required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="epochTo" sm={3}>To (epoch)</Label>
                    <Col sm={9} className="presetData">
                        {timestampTo != null ? timestampTo : "(not specified)"}
                        <Input type="hidden"
                            name="timestampTo"
                            id="timestampTo"
                            value={timestampTo}
                            maxLength="10"
                            required />
                    </Col>
                    <span id='timestampMessage'></span>
                </FormGroup>
                <Col>
                    <div className='btn-toolbar justify-content-center'>
                        <DataRangeDownload
                            timestampFrom={timestampFrom}
                            timestampTo={timestampTo}
                            buttonText="Download CSV"
                            fileName='EDG_BoardDataRange'
                            showLabel='true'
                            className='button-list btn downloadButton' />
                        <Button className='button-list' color="secondary" href='/boarddata'>Cancel</Button>
                    </div>
                </Col>
            </Form>
        </Col>
    )
}
