import React, { useState } from 'react';
import { Table, FormGroup, Label, Input, Button } from 'reactstrap';
import { usePageNumber } from './hooks/usePageNumber';
import '../stylesheets/custom.css';
import '../stylesheets/buttons.css';
import { BoardDataTablePcbsn } from './BoardDataTablePcbsn';

export function BoardTable({ title, boards, checkedBoards, onBoardSelected, onAllSelected, allSelected,
                            showCheckboxes, onEditBoardName, setCompany, resetPages }) {
    const { pageNumber } = usePageNumber();
    const [pagedBoards, setPagedBoards] = useState([]);
    var token = localStorage.getItem("token");
    var base64Payload = token.split('.')[1];
    var payload = window.atob(base64Payload);
    var payloadValues = payload.valueOf();
    var parsedPayload = JSON.parse(payloadValues);
    const role = parsedPayload.UserRole;

    const handleFilterByCompany = (b) => {
        fetch(`api/boards/company/${b.companyId}/${pageNumber}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => setPagedBoards(data))
        .catch(e => console.log(e));
        setCompany(b.companyId);
        resetPages();
    }

    return (
        <FormGroup check>
            <h3 className='table-header'>{title}</h3>
            <Table striped>
                <thead>
                    <tr>
                        <th className={showCheckboxes ? '' : 'hidden'}>
                            <Label check>
                                <Input id="checkbox_select_all" className='centered' onChange={onAllSelected} type="checkbox" checked={allSelected} disabled="disabled" />
                            </Label>
                        </th>
                        <th className='selectAllSpace'></th>
                        <th className={role === "ReadOnly" ? 'hidden' : ''}>Select All</th>
                        {role === "Admin" ? <th>Company</th> : null }
                        <th>Pcb SN</th>
                        <th>Pcb MN</th>
                        <th>Board Name</th>
                        <th>Group</th>
                        <th className={role === "ReadOnly" ? 'hidden' : ''}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        checkedBoards.map(b => {
                            return (
                                <tr key={b.id}>
                                    <td className={showCheckboxes ? '' : 'hidden'}>
                                        <Label>
                                            <Input className='centered' key={b} onChange={e => onBoardSelected(b, e)} type="checkbox" checked={true}/>
                                        </Label>
                                    </td>
                                    <td></td>
                                    <td className={role === "ReadOnly" ? 'hidden' : ''}></td>
                                    {role === "Admin" ? <td>{b.companyName}</td> : null}
                                    <BoardDataTablePcbsn pcbSn={b.pcbSn} />
                                    <td>{b.pcbMn}</td>
                                    <td>{b.boardName}</td>
                                    <td>{b.group}</td>
                                    <td className={role === "ReadOnly" ? 'hidden' : ''}>
                                        <Button className='table-button' color='info' onClick={() => onEditBoardName(b)}>Edit Board Name</Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tbody>
                    {
                        boards.map(b => {
                            return (
                                <tr key={b.id}>
                                    <td className={showCheckboxes ? '' : 'hidden'}>
                                        <Label check>
                                            <Input className='centered' key={b} onChange={e => onBoardSelected(b, e)} type="checkbox" checked={false}/>
                                        </Label>
                                    </td>
                                    <td></td>
                                    <td className={role === "ReadOnly" ? 'hidden' : ''}></td>
                                    {role === "Admin" ?
                                        <td>
                                            <Button className='table-button' color='link' onClick={() => handleFilterByCompany(b)}>{b.companyName}</Button>
                                        </td>
                                        : null
                                    }
                                    <BoardDataTablePcbsn pcbSn={b.pcbSn} />
                                    <td>{b.pcbMn}</td>
                                    <td>{b.boardName}</td>
                                    <td>{b.group}</td>
                                    <td className={role === "ReadOnly" ? 'hidden' : ''}>
                                        <Button className='table-button' color='info' onClick={() => onEditBoardName(b)}>Edit Board Name</Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </FormGroup>
    )
}