import { useState } from 'react';

export function useAllData() {
    const [allData, setAllData] = useState([]);
    const [allDataUrl, setAllDataUrl] = useState([]);
    const token = localStorage.getItem("token");

    const getAllData = (url) => {
        if(url == allDataUrl) return;
        setAllDataUrl(url);
        setAllData([]);
        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => {
            if (resp.ok) {
                return resp.json()
            } else {
                throw new Error(resp.status + " No data returned")
            }
        })
        .then((data) => {
            setAllData(data);
            // Check if the element exists
            var element = document.getElementById("checkbox_select_all");
            if (typeof(element) != 'undefined' && element != null)
            {
                // Now that our data is fetched, allow the checkbox to be useable
                document.getElementById("checkbox_select_all").removeAttribute("disabled");
            }
        })
        .catch(e => console.log(e));
    }

    return { allData, getAllData };
}
