import React, { useState, useEffect } from 'react'; 
import { Alert, Button } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/login.css';
import '../stylesheets/logo.css';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';

export function LogoutPage() {
    const [loggedOut, setLoggedOut] = useState(false);

    useEffect(() => {
        localStorage.removeItem("appToken");
        localStorage.removeItem("token");
        setLoggedOut(true);
    }, []);

    if(loggedOut) {
        return (
            <Alert className='alert-alignment' color='primary'>
                You are logged out. 
                <Button className='alert-link' color='link' href="/" >Login</Button>
            </Alert>
        )
    } else {
        return (
            <span>Loading...</span>
        )
    }
}