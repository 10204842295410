import React, { useState, useEffect } from 'react';
import { Button, Alert } from 'reactstrap';
import { useAllUsers } from './hooks/useAllUsers';
import { UsersTable } from './UsersTable';
import { Pagination } from './Pagination';
import { usePageNumber } from './hooks/usePageNumber';
import { useRole } from './hooks/useRole';
import '../stylesheets/custom.css';
import '../stylesheets/buttons.css';

export function ManageUsersPage() {
    const { pagedUsers, getPagedUsers, allUsers, getAllUsers, setAllUsers} = useAllUsers();
    const { pageNumber, incrementPageNumber, decrementPageNumber, resetPageNumber, lastPageNum, updateLastPageNum   } = usePageNumber();
    const [loading, setLoading] = useState(true);
    const [isCompanySelected, setCompany] = useState(false);
    const [alertText, setAlertText] = useState("");
    const { userRole, username } = useRole();
    const token = localStorage.getItem("token");
    const handleSetCompany = (c) => {
        setCompany(!isCompanySelected);
        window.companyId = c;
    };

    useEffect(() => {
        if (isCompanySelected) {
            getPagedUsers(`api/users/${window.companyId}/${pageNumber}`);
            // here we are using the next page users length to tell us if we are currently on the lastPage
            getAllUsers(`api/users/${window.companyId}/${pageNumber + 1}`);
        } else {
            getPagedUsers(`api/users/page/${pageNumber}`);
            getAllUsers(`api/users/`);
            // only update lastPageNum when no company selected
            updateLastPageNum(Math.ceil(allUsers.length / 20));
        }
        if (allUsers.length > 0 || pagedUsers.length > 0) setLoading(false);
    }, [pageNumber, allUsers, isCompanySelected, alertText]);

    const handleDeleteUser = (usr) => {
        setLoading(true);
        fetch(`api/users/${usr.id}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(resp => {
                setLoading(false);
                if (resp.ok) {
                    setAllUsers([]); // one deleted: invalidate the view
                } else {
                   resp.text().then((text) => setAlertText(text));
                }
            })
            .catch(e => console.log(e));
    }


    const reinviteUser = (usr) => {
        setLoading(true);
        fetch(`api/users/invite/${usr.companyId}`, {
            method: 'POST',
            body: JSON.stringify({
                username: usr.username,
                userRole: usr.userRole,
                companyId: usr.companyId,
                AddedBy: username
            }),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then(resp => {
                if (resp.status == 403) {
                    resp.text().then((text) => {
                        setAlertText(text);
                    });
                } else {
                    if (!resp.ok) {
                        resp.text().then((text) => setAlertText(text));
                    }
                    setAllUsers([]); // one reinvited: invalidate the view by changing allUsers
                }
                setLoading(false);
            })
            .catch(e => console.log(e));
    }

    return (
      <React.Fragment>
        <h1 className='table-header'>Manage Users</h1>
        <Alert color='primary' className={alertText == "" ? 'hidden' : 'table-header'}>{alertText}</Alert>
            <Button className='button-list' color='primary' href='/inviteUser' title="Select 'Invite User' to invite someone new via email. They will need to setup their account before it can be used.">Invite User</Button>
            <Button className='button-list' color='primary' href='/registerUser' title="Select 'Register User' to create an account that is ready for immediate use.">Register User</Button>
        <UsersTable
                allUsers={pagedUsers}
                setCompany={handleSetCompany}
                resetPages={resetPageNumber}
                title="All Users"
                showError={setAlertText}
                delUser={handleDeleteUser}
                reinviteUser={reinviteUser}
                loading={loading}
        />
        <Pagination currentPage={pageNumber}
            previous={decrementPageNumber}
            next={incrementPageNumber}
            lastPage={isCompanySelected ? (allUsers.length <= 0) : (pageNumber >= lastPageNum)}
            selectedAll={false}
          />
        </React.Fragment>
    );
}
