import React, { useEffect, useState } from 'react';
import { Button, Alert } from 'reactstrap';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';
import { useLocation } from "react-router-dom";

export function EmailVerifyPage() {
    const [success, setSuccess] = useState(null);
    const [alertString, setAlertString] = useState("Something went wrong.");
    const [paramsMissing, setParamsMissing] = useState(null);
    const [paramsInvalid, setParamsInvalid] = useState(null);

    const location = useLocation();
    const paramUsername = new URLSearchParams(location.search).get('username');
    const paramEmailToken = new URLSearchParams(location.search).get('emt');

    // Check to see if an existing user is logged in
    const token = localStorage.getItem("token");
    const url = new URL(window.location.href);

    useEffect(() => {
        // If an exsting user is logged in, log them out.
        if (token != null) {
            localStorage.removeItem("appToken");
            localStorage.removeItem("token");
        }

        // If this if/else is placed outside of the useEffect(), we get recurring page renders which prevents the page from working.
        if (url.searchParams.has('username') && url.searchParams.has('emt') && paramUsername != "" && paramEmailToken != "") {
            setParamsMissing(false);

            // If we call userVerify() without the useEffect(), the API function is called multiple times.
            // This can result in the server sending duplicate emails to the user.
            userVerify(paramUsername, paramEmailToken);
        } else {
            setParamsMissing(true);
            setAlertString("You are not permitted to access this page.");
        }
    }, []);

    const userVerify = async (paramUsername, paramEmailToken) => {
        const response = await fetch(`app/userverify/${paramUsername}/${paramEmailToken}`, {
            method: 'POST',
            body: JSON.stringify({
                Username: paramUsername,
                PasswordResetToken: paramEmailToken
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(resp => {
            if (resp.status == 400) {
                setSuccess(false);
                resp.text().then((text) => {
                    setAlertString(text);
                    if (text == "User not found.") {
                        setParamsInvalid(true);
                    }
                });
            } else {
                setSuccess(true);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            }
        })
        .catch(e => console.log(e));
    }

    if (paramsMissing === true || paramsInvalid === true) {
        return (
            <Alert className='alert-alignment' color='danger'>{`${alertString}`}
                <Button className='alert-link' color='link' href="/" >Go to login page?</Button>
            </Alert>
        )
    }

    if (success === true) {
        return (
            <Alert className='alert-alignment' color='success'>{`${alertString}`}
                <Button className='alert-link' color='link' href="/" >Proceed to the login page?</Button>
            </Alert>
        );
    } else if (success === false) {
        return (
            <Alert className='alert-alignment' color='danger'>{`${alertString}`}
                <Button className='alert-link' color='link' href="/" >Go to login page?</Button>
            </Alert>
        );
    } else {
        return (
            <span>Loading...</span>
        );
    }

}