import { useState } from 'react';
export function useGroups() {
    const token = localStorage.getItem("token");
    const [allGroups, setAllGroups] = useState([]);
    const [companyGroups, setCompanyGroups] = useState([]);

    const sortAlphabetically = (arr) => {
        var sorted = arr.sort((a, b) => {
            return a.localeCompare(b);
        });
        return sorted;
    }
    const getAllGroupNames = () => {
        fetch(`api/group`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => setAllGroups(sortAlphabetically(data)))
        .catch(e => console.log(e));
    }

    const getGroupsByCompany = (compId) => {
        fetch(`api/group/company/${compId}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => setCompanyGroups(sortAlphabetically(data)))
        .catch(e => console.log(e));
    }

    return {  allGroups, companyGroups, getAllGroupNames, getGroupsByCompany };
}