import { useState } from 'react';

export function useAllBoards() {
    const [allBoards, setAllBoards] = useState([]);
    const [allBoardsUrl, setAllBoardsUrl] = useState([]);
    const [allBoardsComplete, setAllBoardsComplete] = useState([]);
    const [allCompleteUrl, setAllCompleteUrl] = useState([]);
    const [pagedBoards, setPagedBoards] = useState([]);
    const [pagedBoardsUrl, setPagedBoardsUrl] = useState([]);
    const token = localStorage.getItem("token");

    const getAllBoards = (url) => {
        if(url == allBoardsUrl) return;
        setAllBoardsUrl(url);
        setAllBoards([]);
        fetch(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => {
            if (resp.ok) {
                return resp.json()
            } else {
                throw new Error(resp.status + " No data returned")
            }
        })
        .then(data => {
            setAllBoards(data)

            // Check if the element exists
            var element = document.getElementById("checkbox_select_all");
            if (typeof (element) != 'undefined' && element != null) {
                // Now that our data is fetched, allow the checkbox to be useable
                document.getElementById("checkbox_select_all").removeAttribute("disabled");
            }
        })
        .catch(e => console.log(e))
    }

    const getAllBoardsComplete = (url) => {
        if(url == allCompleteUrl) return;
        setAllCompleteUrl(url);
        setAllBoardsComplete([]);
        fetch(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => {
            if (resp.ok) {
                return resp.json()
            } else {
                throw new Error(resp.status + " No data returned")
            }
        })
        .then(data => {
            setAllBoardsComplete(data)
        })
        .catch(e => console.log(e))
    }

    const getPagedBoards = (url) => {
        if (url == pagedBoardsUrl) return;
        setPagedBoardsUrl(url);
        setPagedBoards([]);
        fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => {
            if (resp.ok) {
                return resp.json()
            } else {
                throw new Error(resp.status + " No data returned")
            }
        })
        .then(data => setPagedBoards(data))
        .catch(e => console.log(e))
    }

    const resetAllBoards = (boards) => {
        setAllBoards(boards);
    }

    const resetPagedBoards = (boards) => {
        setPagedBoards(boards);
    }

    const removeFromAllBoards = (b) => {
        setAllBoards(allBoards.filter(board => board !== b));
    }

    const removeFromPagedBoards = (b) => {
        setPagedBoards(pagedBoards.filter(board => board !== b));
    }

    const addToAllBoards = (b) => {
        setAllBoards([...allBoards, b]);
    }

    const addToPagedBoards = (b) => {
        setPagedBoards([...pagedBoards, b]);
    }

    return { allBoards, allBoardsComplete, getAllBoards, getAllBoardsComplete, pagedBoards, getPagedBoards, removeFromAllBoards, removeFromPagedBoards, addToAllBoards, addToPagedBoards, resetAllBoards, resetPagedBoards };
}
