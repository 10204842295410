import React, { useState } from 'react';
import '../stylesheets/custom.css';
import { ConfirmDelete } from './ConfirmDelete';

export function CompanyRow({ company }) {
    const [deleted, setDeleted] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const token = localStorage.getItem("token");

    const handleDelete = (c) => {
        fetch(`api/companies/${c.id}`, {
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.ok ? setDeleted(true) : setDeleted(false))
        .catch(e => console.log(e));
        deleteToggle();
    }

    const deleteToggle = () => {
        setDeleteModal(!deleteModal);
    }

    return (
        <tr className={deleted ? 'hidden' : ''}>
            <td></td>
            <td>{company.id}</td>
            <td>{company.companyName}</td>
            <td>
          <ConfirmDelete
            deleteMessage={`${company.companyName} from the database. (It is your responsibility to verify that no users or boards are associated with this company name before you delete it!)`}
            buttonLabel="Delete"
            deleteSelected={() => handleDelete(company)}
            deleteModal={deleteModal}
            deleteToggle={deleteToggle}
          />
            </td>
        </tr>
    );
}
