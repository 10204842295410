import React, { useState, useEffect } from 'react';
import { useAllData } from './hooks/useAllData';
import { usePageNumber } from './hooks/usePageNumber';
import { useAllBoards } from './hooks/useAllBoards';
import { useDeleted } from './hooks/useDeleted';
import { useSelectedBoards } from './hooks/useSelectedBoards';
import { useGroups } from './hooks/useGroups';
import { useAllChecked } from './hooks/useAllChecked';
import { useSearchText } from './hooks/useSearchText';
import { ConfirmDelete } from './ConfirmDelete';
import { Form, Input, Button, InputGroup, Alert, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/dataPage.css';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';
import { BoardDataTable } from './BoardDataTable';
import { Pagination } from './Pagination';
import { Download } from './Download';
import { DropdownFilter } from './DropdownFilter';
import { useParams } from 'react-router-dom';
import { useAllCompanies } from './hooks/useAllCompanies';

export function BoardDataPage() {
    const { companyId, pcbSN } = useParams();
    const { allData, getAllData } = useAllData();
    const { allBoards, getAllBoards, pagedBoards, getPagedBoards, removeFromPagedBoards, addToPagedBoards, resetPagedBoards } = useAllBoards();
    const { selectedBoards, addToSelectedBoards, removeFromSelectedBoards, selectedAll, rowCount } = useSelectedBoards();
    const { allGroups, companyGroups, getAllGroupNames, getGroupsByCompany } = useGroups();
    const { pageNumber, incrementPageNumber, decrementPageNumber, resetPageNumber, lastPageNum, updateLastPageNum } = usePageNumber();
    const { deleted, isDeleted, isNotDeleted } = useDeleted();
    const { searchText, updateSearchText } = useSearchText();
    const [deleteModal, setDeleteModal] = useState(false);
    const { getAllCompanies, allCompanies } = useAllCompanies();
    const [allSelected, setAllSelected] = useState(false);
    const [whichGroup, setWhichGroup] = useState('All Data');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    const token = localStorage.getItem("token");
    var base64Payload = token.split('.')[1];
    var payload = window.atob(base64Payload);
    var payloadValues = payload.valueOf();
    var parsedPayload = JSON.parse(payloadValues);
    const role = parsedPayload.UserRole;

    useEffect(() => {
        var pageUrl = ``;

        if(companyId && isSelected === false) {             
            pageUrl = `api/boardData/company/${companyId}/${pageNumber}`;
        } else if (isSelected === true) {
            pageUrl = `api/boarddata/group/${whichGroup}/${pageNumber}`;
        } else if (pcbSN) {
            pageUrl = `api/boardData/pcbSn/${pcbSN}/${pageNumber}`;
        } else {
            pageUrl = `api/boardData/page/${pageNumber}`;
        }

        // note: with this change, SelectAll means select all on this page!
        getPagedBoards(pageUrl);

        // calculate the last page number Note that a blank page is possible!
        // we would know this current page is the last page only if pagedBoards.length < 20
        // we would only update lastPageNum if pageNumber == lastPageNum
        if (lastPageNum == null || pageNumber == lastPageNum) {
            var lpn = (pagedBoards.length >= 20) ? pageNumber + 1 : pageNumber; // 20 items per page - should be a var!
            updateLastPageNum(lpn);
        }
            
    }, [pageNumber, pagedBoards, whichGroup]);


    const deleteToggle = () => {
        setDeleteModal(!deleteModal);
    }

    const updateWhichGroup = (e) => {
        setWhichGroup(e);

        // If "Select All" box was checked, uncheck it and handle selection arrays
        if (allSelected) {
            resetPagedBoards(selectedBoards.slice(0, 20));
            selectedAll([]);
            setAllSelected(false);
        }

        // If a Group is selected while user is on page 2 or beyond, 
        // we need to reset the page number when the box is unselected 
        // to ensure Prev / Next buttons display correctly
        resetPageNumber();

        // Also clear anything that was previously selected
        selectedAll([]);

        if (e === "All Data") {
            // isSelected refers to a group being selected
            setIsSelected(false);
        } else {
            setIsSelected(true);
        }
    }

    const toggleFilterByGroup = () => {
        if(companyId) {
            getGroupsByCompany(companyId);
        } else {
            getAllGroupNames();
        }
        setDropdownOpen(!dropdownOpen);
    }

    const toggleFilterByCompany = () => {
        getAllCompanies(`api/companies/`);
        setCompanyDropdownOpen(!companyDropdownOpen);
    }


    const handleBoardSelected = (b, e) => {
        if(e.target.checked) {
            addToSelectedBoards(b);
            removeFromPagedBoards(b);
        } else {
            removeFromSelectedBoards(b);
            addToPagedBoards(b);
            // we removed a row from selection therefore not allSelected
            setAllSelected(false);
        }
    }

    const handleAllSelected = (e) => {
        if (e.target.checked) {
            // now, all selected just means whatever is on the page: currently selected and paged
            selectedAll(selectedBoards.concat(pagedBoards));
            resetPagedBoards([]);
            setAllSelected(true); // the entire page is selected
        } else {            
            resetPagedBoards(selectedBoards.slice(0, 20));
            selectedAll([]);
            setAllSelected(false);
        }
    }

    const deleteSelectedData = () => {
        var selectedIds = selectedBoards.map(x => x.id);
        fetch(`api/boardData/bulkdelete`, {
            method: "DELETE",
            body: JSON.stringify({
                ids: selectedIds
            }),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(resp => resp.ok ? isDeleted() : isNotDeleted())
        .catch(e => console.log(e))
    }

    const handleSearch = (e) => {
        updateSearchText(e.target.value);
        getAllBoards(`api/boardData/search/${e.target.value}/${pageNumber}`);
    }

    if(deleted === null) {
        return (
            <Alert className='alert-alignment' color='danger'>Something went wrong.
                <Button color='link' className='alert-link' href='/boarddata'>Back</Button>
            </Alert>
        );
    } else if(deleted === true) {
        return (
            <Alert className='alert-alignment' color='success'>Data successfully deleted.
                <Button color='link' className='alert-link' href='/boarddata'>Back</Button>
            </Alert>
        );
    }
    

    return (
        <React.Fragment>
            <div className='half-page'>
                <h3>{role === 'ReadOnly' || role === 'EndClient' || role === 'GuestClient' ? "Select data to download:" : "Select data to download / delete:"}</h3>
                <div className="buttonList">
                    <Button color='primary' className={companyId || pcbSN ? 'button-list' : 'hidden'} href='/boarddata'>View All Data</Button>
                    <Dropdown className={companyId || pcbSN || role === 'Client' || role === 'ReadOnly' || role === 'Tech' || role === 'EndClient' || role === 'GuestClient' ? 'hidden' : ''} isOpen={companyDropdownOpen} toggle={toggleFilterByCompany}>
                        <DropdownToggle color='primary' className='button-list' caret>
                            Filter By Company
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>Companies</DropdownItem>
                            {
                                allCompanies.map(c => {
                                    return (
                                        <DropdownItem key={c.id} href={`/boarddata/${c.id}`}>{c.companyName}</DropdownItem>
                                )})
                            }
                        </DropdownMenu>
                    </Dropdown>
                    <DropdownFilter filterButtonClass={pcbSN ? 'hidden' : 'primaryColor button-list'} dropdownOpen={dropdownOpen} 
                                    onToggle={toggleFilterByGroup} listItems={companyId ? companyGroups : allGroups} 
                                    onFilter={updateWhichGroup} buttonText="Filter By Group" listHeader="Groups" />
                    <ConfirmDelete
                        deleteButtonClass={role === 'ReadOnly' || role === 'EndClient' || role === 'GuestClient' ? 'hidden' : 'button-list'}
                        deleteMessage="the selected data from the database."
                        deleteSelected={deleteSelectedData}
                        buttonLabel="Delete Selected"
                        deleteModal={deleteModal}
                        deleteToggle={deleteToggle}
                        btnDisabled={rowCount < 1}
                    />
                    <Download
                        jsonData={selectedBoards.length === 0 ? allBoards : selectedBoards}
                        buttonText="Download CSV"
                        fileName='EDG_BoardData'
                        showLabel='true'
                        className='btn btn-info downloadButton button-list'
                        btnDisabled={rowCount < 1}
                    />
                    <Button className='button-list downloadButton' href='/dataRangeSelect'>Download Range to CSV</Button>
                </div>
                <InputGroup className={companyId || pcbSN || isSelected ? 'hidden' : ''}>
                    <Input id="text" placeholder="Search" value={searchText} onChange={e => handleSearch(e)} />
                </InputGroup> 
            </div>
            <Form>
                <Alert color='primary' className={rowCount === 0 ? 'hidden' : 'table-header'}>Selected: <span className='font-weight-bold'>{rowCount} </span>rows of data.</Alert>
                <BoardDataTable
                    userRole={role} allSelected={allSelected} showCheckboxes={true} selectAllDisabled={false}
                    title={pcbSN ? pcbSN : whichGroup}
                    checkedBoards={selectedBoards.slice(0, 20 - pagedBoards.length)}
                    boards={pagedBoards} 
                    onBoardSelected={handleBoardSelected} onAllSelected={handleAllSelected} />
                <Pagination currentPage={pageNumber} 
                        previous={decrementPageNumber} 
                        next={incrementPageNumber} 
                        lastPage={(pageNumber >= lastPageNum || pagedBoards.length < 20)}
                        selectedAll={(selectedBoards.length > 0 || allSelected)} />
            </Form>
        </React.Fragment>
    );
}
