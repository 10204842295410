import React, { useState, useEffect } from 'react'; 
import { Button } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/buttons.css';
import '../stylesheets/alert.css';
import { ConfirmDelete } from './ConfirmDelete';
import moment from 'moment';

export function UserRow({ user, onFilterByCompany, showError, delUser, reinviteUser}) {
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  }


    function displayUserRole(user) {
        let userRole = user.userRole;
        switch(userRole) {
           case 0:
               return "Admin";
            case 1:
                return "Client";
            case 2:
                return "ReadOnly";
            case 3:
                return "Tech";
            case 4:
                return "EndClient";
            case 5:
                return "GuestClient"
            default: 
                return "Unknown"; 
        }
    }

    const formatPhoneNumber = (user) => {
        let displayPhone = user.phone;
        if(displayPhone !== undefined) {
            displayPhone = displayPhone.slice(2);
            let updatedPhone = displayPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
            if(updatedPhone) {
                displayPhone = '(' + updatedPhone[1] + ') ' + updatedPhone[2] + '-' + updatedPhone[3] 
            }
        }
        return displayPhone;
    }

  const formatForHighlight = (user, thestring) => {
    if (user.lastVerified != "incomplete") return (thestring);
    if (moment().isAfter(user.passwordResetTokenExpires)) {
      thestring = (thestring == null) ? "Invite Expired" : thestring;
      return (<span className='red-highlight'>{thestring}</span>);
    }

    thestring = (thestring == null && user.lastVerified == "incomplete") ? "Invited" : thestring;
    return (<mark>{thestring}</mark>);
  }

  const boardsButton = (user) => {
    if (user.lastVerified != "incomplete")
      return (
        <Button className='table-button' color='primary' href={`manageBoards/${user.companyId}`}>User&apos;s Boards</Button>
      );
    if (moment().isAfter(user.passwordResetTokenExpires))
      return (<span className='red-highlight'>Invite Expired</span>);
    return (<mark>Invited</mark>);
  }

  const editUserButton = (user) => {
    if (user.lastVerified != "incomplete")
      return (
        <Button className='table-button' color='info' href={`/editUser/${user.id}`} >Edit User</Button>
      );
    if (moment().isAfter(user.passwordResetTokenExpires))
      return (
        <div className='btn-toolbar justify-content-left'>
          <Button className='table-button' color='primary' onClick={() => reinviteUser(user)}
            title="Re-Invite this user">Re-Invite</Button>
          <ConfirmDelete
            deleteModal={deleteModal}
            deleteToggle={deleteToggle}
            deleteSelected={() => delUser(user)}
            buttonLabel="Delete"
            deleteButtonClass='table-button'
            deleteMessage={`${user.username} from the database.`}
          />
        </div>
      );
    return (
      <div className='btn-toolbar justify-content-left'>
        <ConfirmDelete
          deleteModal={deleteModal}
          deleteToggle={deleteToggle}
          deleteSelected={() => delUser(user)}
          buttonLabel="Delete"
          deleteButtonClass='table-button'
          deleteMessage={`${user.username} from the database.`}
        />
      </div>
      );
  }

    return (
        <tr>
            <td></td>
            <td>{formatForHighlight(user, user.username)}</td>
            <td>{displayUserRole(user)}</td>
            <td>
                <Button className='table-button' color='link' onClick={() => onFilterByCompany(user)}>{user.companyName.indexOf("auto_") == 0 ? "" : user.companyName}</Button>
            </td>
            <td>{formatForHighlight(user, user.firstName)}</td>
            <td>{formatForHighlight(user, user.lastName)}</td>
        <td>{formatForHighlight(user, formatPhoneNumber(user))}</td>
            <td>
              {editUserButton(user)}
            </td>
            <td>
              {boardsButton(user)}
            </td>
        </tr>
    );
}
