import { useState } from 'react';

export function usePageNumber() {
    const [pageNumber, setPageNumber] = useState(1);
    const [lastPageNum, setLastPageNum] = useState();
    

    const incrementPageNumber = () => {
        setPageNumber(pageNumber < lastPageNum ? pageNumber + 1 : lastPageNum);
    }
    const decrementPageNumber = () => {
        setPageNumber((pageNumber > 1) ? pageNumber - 1 : 1);
    }
    const resetPageNumber = () => {
        setPageNumber(1);
    }
    const updateLastPageNum = (lpn) => {
        setLastPageNum(lpn);
    }

    return { pageNumber, incrementPageNumber, decrementPageNumber, resetPageNumber, lastPageNum, updateLastPageNum };
}
