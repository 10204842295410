import React from 'react';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import '../stylesheets/custom.css';

export function DropdownFilter({ filterButtonClass, dropdownOpen, onToggle, buttonText, listHeader, listItems, onFilter }) {

    return (
        <Dropdown isOpen={dropdownOpen} toggle={onToggle}>
            <DropdownToggle className={filterButtonClass} caret>
                {buttonText}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem header>{listHeader}</DropdownItem>
                {
                    listItems.map(b => {
                        return (
                            <DropdownItem key={b} onClick={onFilter.bind(this, b)}>{b}</DropdownItem>
                    )})
                }
                <DropdownItem onClick={onFilter.bind(this, "All Data")}>All Data</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}