import React from 'react';
import { Table, FormGroup } from 'reactstrap';
import '../stylesheets/custom.css';
import { CompanyRow } from './CompanyRow';

export function CompaniesTable({ allCompanies }) {
    return (
        <FormGroup check>
            <h3 className='table-header'>All Companies</h3>
            <Table striped>
                <thead>
                    <tr>
                        <th></th>
                        <th>Company ID</th>
                        <th>Company</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                    <tbody>
                    {
                        allCompanies.map(c => {
                            return (
                                <CompanyRow key={c.id} company={c} />
                            )
                        })
                    }
                </tbody>
            </Table>
        </FormGroup>
    );
}