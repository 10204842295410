import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, InputGroup, Input, InputGroupAddon, Alert, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Pagination } from './Pagination';
import { useAllChecked } from './hooks/useAllChecked';
import { useSelectedBoards } from './hooks/useSelectedBoards';
import { usePageNumber } from './hooks/usePageNumber';
import '../stylesheets/custom.css';
import '../stylesheets/alert.css';
import '../stylesheets/forms.css';
import '../stylesheets/buttons.css';
import { useAllBoards } from './hooks/useAllBoards';
import { BoardTable } from './BoardTable';
import { ConfirmDelete } from './ConfirmDelete';
import { useAllData } from './hooks/useAllData';

export function ManageBoardsPage() {
    const { allData, getAllData } = useAllData();
    const { allBoards, allBoardsComplete, getAllBoards, getAllBoardsComplete, pagedBoards, getPagedBoards, removeFromAllBoards, removeFromPagedBoards, addToAllBoards, addToPagedBoards, resetAllBoards, resetPagedBoards } = useAllBoards();
    const { selectedBoards, addToSelectedBoards, removeFromSelectedBoards, selectedAll, rowCount } = useSelectedBoards();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleted, setDeleted] = useState(null);
    const [deleteError, setDeleteError] = useState("Something went wrong when deleting boards.");
    const [alertString, setAlertString] = useState("Something went wrong.");
    const [boardName, setBoardName] = useState("");
    const [boardToName, setBoardToName] = useState();
    const [editModal, setEditModal] = useState(false);
    const { pageNumber, incrementPageNumber, decrementPageNumber, resetPageNumber, lastPageNum, updateLastPageNum  } = usePageNumber();
    const { allChecked, isChecked } = useAllChecked();
    const [allSelected, setAllSelected] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [success, setSuccess] = useState(null);
    const [whichProperty, setWhichProperty] = useState("");
    const token = localStorage.getItem("token");
    var base64Payload = token.split('.')[1];
    var payload = window.atob(base64Payload);
    var payloadValues = payload.valueOf();
    var parsedPayload = JSON.parse(payloadValues);
    const role = parsedPayload.UserRole;

    const [isCompanySelected, setCompany] = useState(false);
    const handleSetCompany = (c) => {
        setCompany(!isCompanySelected);
        window.companyId = c;
        selectedAll([]);
        setAllSelected(false);
        document.getElementById("button_delete_board").setAttribute("disabled", "");
    }

    useEffect(() => {
        document.getElementById("button_delete_board").setAttribute("disabled", "");

        if (isCompanySelected) {
            getPagedBoards(`api/boards/company/${window.companyId}/${pageNumber}`);

            // Get the NEXT page of data, and count it.  If > 0, then display the "Next" button
            // it might seem odd to update LastPageNum first, yet that will prevent an incorrect value
            updateLastPageNum((allBoards.length > 0) ? pageNumber + 1 : pageNumber);
            getAllBoards(`api/boards/company/${window.companyId}/${pageNumber + 1}`);
            
            // Since getAllBoards (above) obtains only a single page of results, we must also fetch an array which is not page restricted.
            getAllBoardsComplete(`api/boards/`);
        } else {
            getPagedBoards(`api/boards/page/${pageNumber}`);
            updateLastPageNum(Math.ceil(allBoards.length / 20));
            getAllBoards(`api/boards/`);
        }

        

    }, [pageNumber, allBoards, isCompanySelected]);

    const handleBoardSelected = (b, e) => {
        if (e.target.checked) {
            addToSelectedBoards(b);
            removeFromPagedBoards(b);
            document.getElementById("button_delete_board").removeAttribute("disabled");
        } else {
            removeFromSelectedBoards(b);
            addToPagedBoards(b);

            if (selectedBoards.length == 1) {
                document.getElementById("button_delete_board").setAttribute("disabled", "");
            }

            if (document.getElementById("checkbox_select_all").checked) {
                isChecked();
            }
        }
    }

    const handleAllSelected = (e) => {
        isChecked();
        if (e.target.checked) {

            if (isCompanySelected) {
                var filteredBoards = allBoardsComplete.filter(x => x.companyId == window.companyId);

                selectedAll(filteredBoards);
                resetPagedBoards([]);
                setAllSelected(true);
                
                if (filteredBoards.length > 0) {
                    document.getElementById("button_delete_board").removeAttribute("disabled");
                }
            } else {
                selectedAll(allBoards);
                resetPagedBoards([]);
                setAllSelected(true);

                if (allBoards.length > 0) {
                    document.getElementById("button_delete_board").removeAttribute("disabled");
                }
            }

        } else {

            // If "Select All" was checked on page 2 or beyond, we need to reset the page number when the box is unselected to ensure Prev / Next buttons display correctly after unselecting "Select All"
            resetPageNumber();

            resetPagedBoards(selectedBoards.slice(0, 20));
            selectedAll([]);
            setAllSelected(false);
            document.getElementById("button_delete_board").setAttribute("disabled", "");
        }
    }
  
    const updateGroupName = (e) => {
        setGroupName(e.target.value);
    }

    const updateGroupOfSelected = () => {
        setWhichProperty("group");
        var selectedIds = selectedBoards.map(x => x.id);
        if (selectedBoards.length > 0) {
            fetch(`api/boards/bulkupdate/group`, {
                method: 'PUT',
                body: JSON.stringify({
                    serialNumbers: selectedIds,
                    propertyName: groupName
                }),
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })
            .then(resp => resp.ok ? setSuccess(true) : setSuccess(false))
            .catch(e => console.log(e))
        } else {
            setAlertString("Please select at least one board.");
            setSuccess(false);
        }
    }

    const deleteToggle = () => setDeleteModal(!deleteModal);

    const handleDeleteSelected = () => {
        var selectedIds = selectedBoards.map(x => x.id);
        fetch(`api/boards/bulkdelete`, {
            method: "DELETE",
            body: JSON.stringify({
                ids: selectedIds
            }),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
        .then((resp) => {
          if (resp.status == 403) {
            setDeleted(false);
            resp.text().then((text) => {
              setDeleteError(text);
            });
          } else {
            resp.ok ? setDeleted(true) : setDeleted(false);
          }
        })
        .catch(e => console.log(e));
        deleteToggle();
    }

    const handleUpdateAllGroups = () => {
        var serialNumbers = selectedBoards.map(x => x.pcbSn);
        fetch(`api/boardData/bulkupdate/group`, {
            method: 'PUT',
            body: JSON.stringify({
                serialNumbers: serialNumbers,
                propertyName: groupName
            }),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
        .then((resp) => {
            if (resp.status == 401) {
                setSuccess(false);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            } else {
                resp.ok ? window.location.reload() : setSuccess(false);
            }
        })
        .catch(e => console.log(e)); 
        setSuccess(null);
    }

    const handleUpdateAllBoardNames = () => {
        var serialNum = [boardToName.pcbSn];
        fetch(`api/boardData/bulkupdate/boardName`, {
            method: 'PUT',
            body: JSON.stringify({
                serialNumbers: serialNum,
                propertyName: boardName
            }),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
        .then(resp => resp.ok ? window.location.reload() : setSuccess(false))
        .catch(e => console.log(e)); 
    }

    const handleEditBoardName = (b) => {
        setBoardToName(b);
        setEditModal(true);
    }

    const updateBoardName = (e) => {
        setBoardName(e.target.value);
    }
    const changeBoardName = () => {
        setWhichProperty("name");
        var currId = [boardToName.id];
        fetch(`api/boards/updateBoardName`, {
            method: 'PUT',
            body: JSON.stringify({
                serialNumbers: currId,
                propertyName: boardName
            }),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
        .then(resp => resp.ok ? setSuccess(true) : setSuccess(false))
        .catch(e => console.log(e));
        toggleEdit();
    }

    const toggleEdit = () => {
        setEditModal(!editModal);
    }

    if(success === true) {
        return (
            <Alert className='alert-alignment' color='success'>{`Successfully changed board ${whichProperty}. Would you like to change this board's existing data to the new ${whichProperty}?`}
                <div>
                    <Button color='link' onClick={whichProperty === "group" ? handleUpdateAllGroups : handleUpdateAllBoardNames} className='alert-link'>Yes</Button>
                    <Button color='link' href='/manageBoards' className='alert-link'>No</Button>
                </div>
            </Alert>
        )
    }
    if(success === false) {
        return (
            <Alert className='alert-alignment' color='danger'>{`${alertString}`}
                <Button href='/manageBoards' className='alert-link'>Back</Button>
            </Alert>
        )
    }

    if(deleted === false) {
      return (
            <Alert className='alert-alignment' color='danger'>{`${deleteError}`}
                <Button color='link' href='/manageBoards' className='alert-link'>Back</Button>
            </Alert>
        )
    }

    if(deleted === true) {
        return (
            <Alert className='alert-alignment' color='success'>Successfully deleted boards.
                <Button color='link' href='/manageBoards' className='alert-link'>Back</Button>
            </Alert>
        )
    }

    return (
        <React.Fragment>
            <div className={role === "ReadOnly" || role === "EndClient" || role === "GuestClient" ? 'hidden' : ''}>
                <h1 className='table-header'>{role === "ReadOnly" || role === "EndClient" || role === "GuestClient" ? 'Manage Boards' : 'Assign Boards to a Group'}</h1>
                <div className={role === "ReadOnly" || role === "EndClient" || role === "GuestClient" ? 'hidden' : ''}>
                    <p>Select registered boards from the list below. Assign the boards to a group using the following field:</p>
                    <InputGroup className='table-header'>
                        <Input onChange={(e) => updateGroupName(e)} id='submit' placeholder="New group name (of selected)"/>
                        <InputGroupAddon addonType="append">
                            <Button className={rowCount === 0 ? "btn disabled downloadButton" : "btn downloadButton"} disabled={rowCount === 0} onClick={updateGroupOfSelected}>Submit</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </div>
                <div className={role === "ReadOnly" || role === "EndClient" || role === "GuestClient" ? "hidden" : "d-flex flex-row align-items-sm-between"}>
                    <Button className='button-space' color='primary' href='/registerBoard'>Register Board</Button>
            <ConfirmDelete
              deleteMessage="the selected boards from the database."
              deleteSelected={handleDeleteSelected}
              buttonLabel='Delete Selected'
              deleteModal={deleteModal}
              deleteToggle={deleteToggle}
            />
                </div>
            </div>
            <Alert color='primary' className={rowCount === 0 ? 'hidden' : 'table-header'}>Selected: <span className='font-weight-bold'>{rowCount} </span>boards.</Alert>
            <BoardTable allSelected={allChecked} showCheckboxes={role === "ReadOnly" || role === "EndClient" || role === "GuestClient" ? false : true}
                                setCompany={handleSetCompany}
                                resetPages={resetPageNumber}
                                title="Registered Boards" checkedBoards={selectedBoards.slice(0, 20 - pagedBoards.length)}
                                boards={pagedBoards} onEditBoardName={handleEditBoardName}
                                onBoardSelected={handleBoardSelected} onAllSelected={handleAllSelected}
                                 />
            <Pagination currentPage={pageNumber} 
                        previous={decrementPageNumber} 
                        next={incrementPageNumber} 
                        lastPage={(pageNumber >= lastPageNum)}
                        selectedAll={(selectedBoards.length > 0 || allSelected)} />
            <Modal isOpen={editModal} >
                <ModalHeader>Edit Board Name</ModalHeader>
                <ModalBody>
                    <InputGroup className='table-header'>
                        <Input onChange={(e) => updateBoardName(e)} id='submit' placeholder="New board name"/>
                    </InputGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggleEdit} color='secondary'>Cancel</Button>
                    <Button onClick={changeBoardName} className='downloadButton btn'>Change Board Name</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
