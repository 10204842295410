import { useState } from 'react';

export function useAllChecked() {
    const [allChecked, setAllChecked] = useState(false);

    const isChecked = () => {
        setAllChecked(!allChecked);
    }

    return { allChecked, isChecked };
}