import { useState } from 'react';

export function useAllUsers() {
    const [allUsers, setAllUsers] = useState([]);
    const [allUsersUrl, setAllUsersUrl] = useState([]);
    const [pagedUsers, setPagedUsers] = useState([]);
    const [usersBoards, setUsersBoards] = useState([]);
    const token = localStorage.getItem("token");

    const getAllUsers = (url) => {
        if(url == allUsersUrl) return;
        setAllUsersUrl(url);
        fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => 
        {
          setAllUsers(data)
          })
        .catch(e => console.log(e))
    }

    const getPagedUsers = (url) => {
        fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => setPagedUsers(data))
        .catch(e => console.log(e))
    }

    const getUsersBoards = (username, page) => {
        fetch(`api/boardIds/user/${username}/${page}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => setUsersBoards(data))
        .catch(e => console.log(e));
    }
    return {  allUsers, getAllUsers, setAllUsers, pagedUsers, getPagedUsers, usersBoards, getUsersBoards };
}
