import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';

export function RegisterCompanyPage() {
    const [companyName, setCompanyName] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [success, setSuccess] = useState(null);
    const [validated, setValidated] = useState(null);
    const [alertString, setAlertString] = useState("Something went wrong.");
    var token = localStorage.getItem("token");

    const addCompanyToCollection = (e) => {
        e.preventDefault();
        fetch(`api/companies`, {
            method: 'POST',
            body: JSON.stringify({
                companyName: companyName
            }),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
        .then((resp) => {
            if (resp.status == 403) {
                setSuccess(null);
                setValidated(false);
                resp.text().then((text) => {
                    setAlertString(text);
                });
            } else {
                resp.ok ? setSuccess(true) : setSuccess(false);
            }
        })
        .catch(e => console.log(e));
        setShowAlert(true);
    }

    const updateCompanyName = (e) => {
        setCompanyName(e.target.value);
    }

   if(success) {
        return (
            <Alert className='alert-alignment' color='success'>{`Successfully added ${companyName} to company collection.`}
                <div>
                    <Button href='/manageCompanies' color='link' className='alert-link'>See all companies</Button>
                    <Button href='/registerCompany' color='link' className='alert-link'>Add another company</Button>
                </div>
            </Alert>
        )
    } else if(success === false) {
        return (
            <Alert className='alert-alignment' color="danger">Something went wrong. Please navigate back to the homepage.
                <Button color='link' className="alert-link" href='/'>Home</Button>
            </Alert>
        );
    }
    
    if(validated === false) {
        return (
            <Alert id='alertNotValid' className='alert-alignment' color="danger">{`${alertString}`}
                <Button color='link' className="alert-link" href='/registerCompany'>Try again</Button>
            </Alert>
        )
    }

    return (
        <div>
            <Form onSubmit={(e) => addCompanyToCollection(e)} className={showAlert ? 'hidden' : ''}>
                <h1 className="table-header">Add Company</h1>
                <FormGroup>
                    <Label for="sn">Company Name</Label>
                    <Input onChange={updateCompanyName} type="text" name="companyName" id="companyName" placeholder="Required" />
                </FormGroup>
                <input value="Submit" type="submit" className="table-header btn downloadButton" />
            </Form>
        </div>
    );
}