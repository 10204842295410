import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { Pagination } from './Pagination';
import { usePageNumber } from './hooks/usePageNumber';
import { CompaniesTable } from './CompaniesTable';
import '../stylesheets/custom.css';
import '../stylesheets/buttons.css';
import { useAllCompanies } from './hooks/useAllCompanies';

export function ManageCompaniesPage() {
    const { allCompanies, pagedCompanies, getAllCompanies, getPagedCompanies } = useAllCompanies();
    const { pageNumber, incrementPageNumber, decrementPageNumber, resetPageNumber, lastPageNum, updateLastPageNum  } = usePageNumber();

    useEffect(() => {
        getPagedCompanies(`api/companies/page/${pageNumber}`)
        getAllCompanies(`api/companies/`);
        
        updateLastPageNum(Math.ceil(allCompanies.length / 20));
    }, [pageNumber, allCompanies]);

    return (
        <div>
            <h1 className='table-header'>Manage Companies</h1>
            <Button className='button-list' color='primary' href='/registerCompany'>Register Company</Button>
            <CompaniesTable allCompanies={pagedCompanies} />
            <Pagination currentPage={pageNumber} 
                        previous={decrementPageNumber} 
                        next={incrementPageNumber} 
                        lastPage={(pageNumber >= lastPageNum)}
                        selectedAll={false} />
        </div>
    );
}
