import React from 'react';
import '../stylesheets/custom.css';

export function BoardDataTablePcbsn({ pcbSn }) {
    return (
        <td>
            <span className='code' id={pcbSn}>{pcbSn}</span>
            <input value="Copy" type="button" color="secondary" className='copyButtonRight' onClick={() => navigator.clipboard.writeText(document.getElementById(pcbSn).innerHTML)} />
        </td>
    );
}