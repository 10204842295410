import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Col } from 'reactstrap';
import '../stylesheets/custom.css';
import '../stylesheets/alert.css';
import '../stylesheets/buttons.css';
import { useAllCompanies } from './hooks/useAllCompanies';
import { useAllUsers } from './hooks/useAllUsers';
import { useRole, userRoles } from './hooks/useRole';
import { v4 as uuid } from 'uuid';
import { LevelWall } from './LevelWallComponent';

export function RegisterBoardPage() {
    const { allCompanies, getAllCompanies } = useAllCompanies();
    const { allUsers, getAllUsers } = useAllUsers();
    const [companyId, setCompanyId] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [pcbMn, setPbcMn] = useState("");
    const [pcbSn, setPcbSn] = useState("");
    const [pcbSnPrefix, setPcbSnPrefix] = useState("");
    const [pcbSnSuffix, setPcbSnSuffix] = useState("");
    const [pcbSnSuffixUser, setPcbSnSuffixUser] = useState("");
    const [uuidOverride, setUuidOverride] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [validated, setValidated] = useState(null); 
    const [alertString, setAlertString] = useState("Please fill out all required fields."); 
    const { userRole } = useRole();
    const [levelWallUp, setLevelWallUp] = useState(false);

    const token = localStorage.getItem("token");
    var base64Payload = token.split('.')[1];
    var payload = window.atob(base64Payload);
    var payloadValues = payload.valueOf();
    var parsedPayload = JSON.parse(payloadValues);
    const role = parsedPayload.UserRole;

    const checkPermission = (permissionVal) => {
        var userVal = userRoles[userRole];
        if (userVal <= permissionVal) {
            return true;
        }
        else return false;
    }

    useEffect(() => {
        getAllUsers(`api/users/`);
        getAllCompanies(`api/companies/`);

        if (!checkPermission(0) && allUsers.length > 0) {
            var firstCompany = allUsers[0];

            setCompanyId(firstCompany.companyId);
            setCompanyName(firstCompany.companyName);
        }

        // Generate new UUID
        var uniqueId = uuid();
        setPcbSnSuffix(uniqueId);
        setPcbSn(uniqueId);
        document.getElementById('pcbSnCopy').innerHTML = pcbSnSuffix;
    }, [allUsers, token]);

    const addToCollection = (e) => {
        setLoading(true);
        e.preventDefault();
        var fetchUrl = '';
        if (pcbSnPrefix != "" && pcbSnSuffix != "") {
            fetchUrl = 'api/boards/' + pcbSnSuffix + '/' + pcbSnPrefix;
        } else if (pcbSnPrefix == "" && pcbSnSuffix != "") {
            fetchUrl = 'api/boards/' + pcbSnSuffix;
        }

        if (pcbSnSuffix.length === 0 || companyName === "") {
            setValidated(false);
        } else if (pcbSnPrefix != pcbSnPrefix.trim()) {
            // Check for trailing whitespace here, because it will not get detected in the request URL
            setValidated(false)
            setAlertString("The Pcb SN Prefix may not use any whitespace.");
        } else if (pcbSnPrefix != pcbSnPrefix.replace('#', '')) {
            // Check for '#' characters here, because they will not all get detected in the request URL
            setValidated(false)
            setAlertString("The Pcb SN Prefix may only consist of alphanumeric characters and the characters '-' and '_'.");
        } else if (pcbSnPrefix != pcbSnPrefix.replace('?', '')) {
            // Check for '?' characters here, because they will not all get detected in the request URL
            setValidated(false)
            setAlertString("The Pcb SN Prefix may only consist of alphanumeric characters and the characters '-' and '_'.");
        } else if (uuidOverride && pcbSnSuffix != pcbSnSuffix.trim()) {
            // Check for trailing whitespace here, because it will not get detected in the request URL
            setValidated(false)
            setAlertString("The Pcb SN Suffix may not use any whitespace.");
        } else if (uuidOverride && pcbSnSuffix != pcbSnSuffix.replace('#', '')) {
            // Check for '#' characters here, because they will not all get detected in the request URL
            setValidated(false)
            setAlertString("The Pcb SN Suffix may only consist of alphanumeric characters and the characters '-' and '_'.");
        } else if (uuidOverride && pcbSnSuffix != pcbSnSuffix.replace('?', '')) {
            // Check for '?' characters here, because they will not all get detected in the request URL
            setValidated(false)
            setAlertString("The Pcb SN Suffix may only consist of alphanumeric characters and the characters '-' and '_'.");
        } else {
            fetch(fetchUrl, {
                method: 'POST',
                body: JSON.stringify({
                    companyId: companyId,
                    companyName: companyName,
                    pcbSn: pcbSn,
                    pcbMn: pcbMn,
                    boardName: "",
                    group: ""
                }),
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })
            .then((resp) => {
                setLoading(false);
                if (resp.status == 403) {
                    setSuccess(null);
                    setValidated(false)
                    resp.text().then((text) => {
                      setAlertString(text);
                      if (text.search("Free") >= 0) {
                        setLevelWallUp(true);
                      }
                    });
                } else {
                    resp.ok ? setSuccess(true) : setSuccess(false);
                }
            })
            .catch(e => console.log(e));
            setShowAlert(true);
        }
    }

    const levelWallToggle = () => {
        setLevelWallUp(!levelWallUp);
    }

    const updatePcbMn = (e) => {
        setPbcMn(e.target.value);
    }

    const updatePcbSn = (e) => {
        setPcbSnPrefix(e.target.value);

        if (e.target.value != null && e.target.value != "") {
            // Prefix specified, so include a "."
            document.getElementById('pcbSnCopy').innerHTML = e.target.value + "." + pcbSnSuffix;
            setPcbSn(e.target.value + "." + pcbSnSuffix);
        } else {
            // No prefix, no "."
            document.getElementById('pcbSnCopy').innerHTML = pcbSnSuffix;
            setPcbSn(pcbSnSuffix);
        }
    }

    const updatePcbSnUuid = (e) => {
        setPcbSnSuffixUser(e.target.value);
        setPcbSnSuffix(e.target.value);

        if (pcbSnPrefix != null && pcbSnPrefix != "") {
            // Prefix specified, so include a "."
            document.getElementById('pcbSnCopy').innerHTML = pcbSnPrefix + "." + e.target.value;
            setPcbSn(pcbSnPrefix + "." + e.target.value);
        } else {
            // No prefix, no "."
            document.getElementById('pcbSnCopy').innerHTML = e.target.value;
            setPcbSn(e.target.value);
        }
    }

    const handleSelectedCompany = (c) => {
        setCompanyName(c.companyName);
        setCompanyId(c.id);
    }

    const handleUuidOverride = (e) => {
        if (e.target.checked) {
            setUuidOverride(true);
            document.getElementById("pcbSnSuffixUser").removeAttribute("disabled");
            document.getElementById("pcbSnSuffixUser").setAttribute("required", "");

            // If a value is already present under uuidOverride when the box is checked,
            // updatePcbSnUuid() will not be called.  So we must set the pcbSnSuffix using
            // the last stored value of pcbSnSuffixUser.
            setPcbSnSuffix(pcbSnSuffixUser);

            if (pcbSnPrefix != null && pcbSnPrefix != "") {
                // Prefix specified, so include a "."
                document.getElementById('pcbSnCopy').innerHTML = pcbSnPrefix + "." + pcbSnSuffixUser;
                setPcbSn(pcbSnPrefix + "." + pcbSnSuffixUser);
            } else {
                // No prefix, no "."
                document.getElementById('pcbSnCopy').innerHTML = pcbSnSuffixUser;
                setPcbSn(pcbSnSuffixUser);
            }
        } else {
            setUuidOverride(false);
            document.getElementById("pcbSnSuffixUser").setAttribute("disabled", "");
            document.getElementById("pcbSnSuffixUser").removeAttribute("required");

            // Generate new UUID
            var uniqueId = uuid();
            setPcbSnSuffix(uniqueId);

            if (pcbSnPrefix != null && pcbSnPrefix != "") {
                // Prefix specified, so include a "."
                document.getElementById('pcbSnCopy').innerHTML = pcbSnPrefix + "." + uniqueId;
                setPcbSn(pcbSnPrefix + "." + uniqueId);
            } else {
                // No prefix, no "."
                document.getElementById('pcbSnCopy').innerHTML = uniqueId;
                setPcbSn(uniqueId);
            }
        }
    }

    const onToggle = () => setDropdownOpen(!dropdownOpen);

    // Instead of the button reloading the page with the Button's href attribute, we call this function.
    // This preserves state (versus reloading) and allows us to prepopulate the form fields.
    const reloadPage = () => {
        setAlertString("Please fill out all required fields.");
        setLoading(false);
        setShowAlert(false);
        setSuccess(null);
        setValidated(null);
    }

    if(success) {
        return (
            <Alert className='alert-alignment' color="success">{`Successfully registered board to ${companyName.indexOf("auto_") == 0 ? "your company" : companyName}.`}
                <div>
                    <Button color='link' className="alert-link" href='registerBoard'>Register another board</Button>
                    <Button color='link' className="alert-link" href='/manageBoards'>View all boards</Button>
                </div>
            </Alert>
        );
    } else if(success === false) {
        return (
            <Alert className='alert-alignment' color="danger">Something went wrong. Please navigate back to the homepage.
                <Button color='link' className="alert-link" href='/'>Home</Button>
            </Alert>
        );
    }

    if(validated === false) {
        return (
           <div>
              <Alert id='alertNotValid' className='alert-alignment' color="danger">{`${alertString}`}
                    <Button color='link' className="alert-link" onClick={() => reloadPage()}>Try again?</Button>
              </Alert>
              <LevelWall
                wallOpen={levelWallUp}
                wallToggle={levelWallToggle}
                wallMessage={alertString}
                declineLink="/manageBoards"
                />
           </div>
        );
    }

    if (loading === true) {
        return (
            <span>Loading...</span>
        )
    }

    return (
        <div>
            <Form onSubmit={(e) => addToCollection(e, pcbSnPrefix)} className={showAlert ? 'hidden' : ''}>
                <h1 className="table-header">Register Board to Company</h1>
                <FormGroup>
                    <Label for="sn" sm={3}>Company Name</Label>
                    {role === "Admin" ?
                        <Col sm={9}>
                            <Dropdown isOpen={dropdownOpen} toggle={onToggle} value={companyName}>
                                <DropdownToggle className='primaryColor button-list' caret>
                                    Select Company
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>Companies</DropdownItem>
                                    {
                                        allCompanies.map(c => {
                                            return (
                                                <div key={c.id}>
                                                    <DropdownItem onClick={() => handleSelectedCompany(c)}>{c.companyName}</DropdownItem>
                                                </div>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                                <h5 className={companyName === "" ? 'hidden' : 'space'}>{`Selected company: ${companyName}`}</h5>
                            </Dropdown>
                        </Col>
                        :
                        <Col className="presetData" sm={9}>
                            {companyName != null && companyName.indexOf("auto_") == 0 ? "(not specified)" : companyName}
                            <input type="hidden" name="companyName" id="companyName" value={companyName} />
                        </Col>
                    }
                </FormGroup>
                <FormGroup>
                    <Label for="sn" sm={3}>Pcb SN Prefix <em>(optional)</em></Label>
                    <Col sm={9}>
                        <Input onChange={updatePcbSn} type="text" name="sn" id="sn" maxLength="60" value={pcbSnPrefix} placeholder="Optional" />
                        <span className='formHint'>Your complete Pcb SN:</span>
                        <span className='formHintCode' id='pcbSnCopy'>{pcbSn}</span>
                        <span className='formHint'><input value="Copy" type="button" color="secondary" onClick={() => navigator.clipboard.writeText(document.getElementById('pcbSnCopy').innerHTML)} /></span>
                    </Col>
                </FormGroup>

                <div className={role === "Admin" ? '' : 'hidden'}>
                    <FormGroup>
                        <Label for="sn" sm={2}>Pcb SN UUID override?</Label>
                        <Label for="sn"><div className="formCheckbox"><Input id="uuidDefined" onChange={handleUuidOverride} type="checkbox" checked={uuidOverride ? true : false} /></div></Label>
                        <Col sm={9}>
                            <Input onChange={updatePcbSnUuid} type="text" name="pcbSnSuffixUser" id="pcbSnSuffixUser" maxLength="36" value={pcbSnSuffixUser} placeholder="Optional" disabled={uuidOverride ? null : "disabled"} />
                        </Col>
                    </FormGroup>
                </div>

                <FormGroup>
                    <Label for="sn" sm={3}>Pcb MN <em>(optional)</em></Label>
                    <Col sm={9}>
                        <Input onChange={updatePcbMn} type="text" name="mn" id="mn" value={pcbMn} placeholder="Optional" />
                    </Col>
                </FormGroup>
                <input value="Register board" type="submit" className="table-header btn downloadButton" />
            </Form>
        </div>
    );
}
