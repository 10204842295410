import React from 'react';
import { Table, FormGroup, Label, Input, Button } from 'reactstrap';
import '../stylesheets/custom.css';
import { QuickView } from './QuickView';

export function BoardDataTable({ showCheckboxes, title, boards, checkedBoards, onBoardSelected, onAllSelected, allSelected, userRole, selectAllDisabled }) {
    return (
        <FormGroup check>
            <h1 className='table-header'>{title}</h1>
            <Table striped>
                <thead>
                    <tr>
                        <th>
                            <Label className={showCheckboxes ? "" : "hidden"} check>
                                <Input id="checkbox_select_all" className='centered' onChange={onAllSelected} type="checkbox" checked={allSelected} disabled={selectAllDisabled}/>
                            </Label>
                        </th>
                        <th></th>
                        <th>{showCheckboxes ? "Select All" : ""}</th>
                        {userRole == 'Admin' ? <th>Device ID</th> : null}
                        <th>Group</th>
                        <th>Pcb SN</th>
                        <th>Pcb MN</th>
                        <th>Hardware Timestamp</th>
                        <th>Time Received</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        checkedBoards.map(b => {
                            return (
                                <tr key={b.id}>
                                    <td>
                                        <Label check className={showCheckboxes ? "" : "hidden"} >
                                            <Input className='centered' key={b} onChange={e => onBoardSelected(b, e)} type="checkbox" checked={true}/>
                                        </Label>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    {userRole == 'Admin' ? <td>{b.idImpDevice}</td> : null}
                                    <td>{b.group}</td>
                                    <td>{b.pcbSn}</td>
                                    <td>{b.pcbMn}</td>
                                    <td>{b.timeSent}</td>
                                    <td>{b.timeReceived}</td>
                                    <td>
                                        <QuickView board={b} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tbody>
                    {
                        boards.map(b => {
                            return (
                                <tr key={b.id}>
                                    <td>
                                        <Label className={showCheckboxes ? "" : "hidden"}  check>
                                            <Input className='centered' key={b} onChange={e => onBoardSelected(b, e)} type="checkbox" checked={false}/>
                                        </Label>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    {userRole == 'Admin' ? <td>{b.idImpDevice}</td> : null}
                                    <td>{b.group}</td>
                                    <td>
                                        <Button className='table-button' color='link' href={`/boarddata/pcbSn/${b.pcbSn}`}>{b.pcbSn}</Button>
                                    </td>
                                    <td>{b.pcbMn}</td>
                                    <td>{b.timeSent}</td>
                                    <td>{b.timeReceived}</td>
                                    <td>
                                        <QuickView board={b} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </FormGroup>
    );
}